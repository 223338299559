<mat-menu #eventMenu="matMenu"
          class="calendar-event-box">
  <ng-template matMenuContent>
    <section class="calendar-event-details p-16"
             (click)="$event.stopPropagation()">
      <button class="close-button"
              mat-icon-button
              (click)="trigger?.closeMenu()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-card-title-group>
        <mat-card-title>{{ startRelative }}</mat-card-title>
        <mat-card-subtitle>{{ startTime }} - {{ endTime }}</mat-card-subtitle>
      </mat-card-title-group>
      <mat-divider></mat-divider>
      <mat-card-content>
        <p matLine>
          <mat-icon>account_circle</mat-icon>
          <a [routerLink]="['/manager', 'clients', client?.id]">
          {{ client?.id }}</a>
          >
          <a [routerLink]="['/manager', 'employees', employee?.id]">
          {{ employee?.id }}</a>
        </p>
        <p matLine>
          <mat-icon>place</mat-icon>
          Hermanowska
        </p>
        <p matLine
           class="text-italic">
          {{ rejected }}
        </p>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <a mat-icon-button color="warn" (click)="remove?.emit(meta)">
          <mat-icon>delete</mat-icon></a>
        <a mat-stroked-button class="pull-right" disabled>
        {{ 'Details' | translate }}</a>
      </mat-card-actions>
      <p><em>last update: {{ updatedAtRelative }} ago</em></p>
    </section>
  </ng-template>
</mat-menu>

<div class="fc-content"
     [matMenuTriggerFor]="eventMenu">
  <div class="fc-time"
       [attr.data-start]="startTime"
       [attr.data-full]="time">
    <span>{{ time }}</span>
  </div>
  <div class="fc-title">{{ title }}</div>
</div>
