<button mat-button
        class="user-button">
  <div fxLayout="row"
       fxLayoutAlign="center center"
       *ngIf="company$ | async as company">
    <avatar class="mr-sm-16"
            [avatar]="company?.profile?.avatar"
            title="{{ company?.profile?.name }}"
            text="{{ company?.profile?.name[0] }}">
    </avatar>
    <span class="username mr-12"
          fxHide
          fxShow.gt-sm>{{ company?.profile?.name }}</span>
    <mat-icon class="s-16"
              fxHide.xs>keyboard_arrow_down</mat-icon>
  </div>
</button>

<!--
<mat-menu #companyMenu="matMenu"
          [overlapTrigger]="false">
  <button mat-menu-item
          *ngFor="let company of companies$ | async; trackBy: trackBy('id')"
          (click)="select(company)">
    <avatar class="mmat-icon"
            [avatar]="company?.profile?.avatar"
            title="{{ company?.profile?.name }}"
            text="{{ company?.profile?.name[0] }}">
    </avatar>
    <span>{{ company?.profile?.name }}</span>
  </button>

  <button mat-menu-item
          [routerLink]="['/companies']"
          *ngIf="isAdmin$ | async">
    <mat-icon>build</mat-icon>
    <span>Manage</span>
  </button>
</mat-menu>
-->
