import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { EmployeesActions } from 'app/db/actions';
import { CompanyService } from 'app/db/services/company.service';
import { ApiService } from 'app/shared/services/api.service';
import { slugify } from 'app/shared/utils';
import { Observable } from 'rxjs';
import { filter, shareReplay, switchMap, tap } from 'rxjs/operators';

import { Employee } from '../models';
import { CompaniesService } from './companies.service';

@Injectable()
export class EmployeesService extends ApiService<Employee> {
  employees$: Observable<Employee[]> = this.companyService.companyId$.pipe(
    filter(Boolean),
    switchMap((companyId: string) =>
      this.afs
        .collection<Employee>(`companies/${companyId}/employees`)
        .valueChanges({ idField: 'id' })
    ),
    tap(() => console.log('employees')),
    tap((employees) => this.store.dispatch(EmployeesActions.set({ employees }))),
    shareReplay()
  );

  model = 'Employee';
  order = { field: 'lastname', direction: 'asc' };

  static createId({ name, lastname }: Partial<Employee>, nextId?: number): string {
    return slugify(`${lastname}-${name}${nextId > 1 ? nextId : ''}`);
  }

  static search(term: string, item: Partial<Employee>): boolean {
    return `${item.name}${item.lastname}`.toLowerCase().indexOf(term.toLocaleLowerCase()) !== -1;
    // return super.search(term, new Client(item));
  }

  constructor(
    private companiesService: CompaniesService,
    private companyService: CompanyService,
    @Inject('firebaseProject') private afs: AngularFirestore,
    private store: Store
  ) {
    super();
  }

  getCollection({ companyId }): AngularFirestoreCollection<Employee> {
    return this.companiesService
      .getDocument({ companyId })
      .collection<Employee>('employees', this.orderQuery);
  }

  getDocument({ companyId, employeeId }): AngularFirestoreDocument<Employee> {
    return this.getCollection({ companyId }).doc<Employee>(employeeId);
  }
}
