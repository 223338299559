import { createAction, props, union } from '@ngrx/store';

import { Company } from '../models';

export const clear = createAction('[DB/Companies] clear');

export const select = createAction('[DB/Companies] select', props<{ company: Partial<Company> }>());

export const set = createAction('[DB/Companies] set', props<{ companies: Company[] }>());
export const subscribe = createAction('[DB/Companies] subscribe');
export const unsubscribe = createAction('[DB/Companies] unsubscribe');

const all = union({
  select,
  set,
  subscribe,
  unsubscribe
});
export type CompaniesActionsUnion = typeof all;
