import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { UsersActions } from '../actions';
import { UsersActionsUnion } from '../actions/users.actions';
import { User } from '../models';

export interface UsersState extends EntityState<User> {
  selectedId: number | string;
  loading: boolean;
  loaded: boolean;
}

export const usersAdapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (user: User) => user?.id,
  sortComparer: (a: User, b: User) => a?.username?.localeCompare(b?.username)
});

const initialState: UsersState = usersAdapter.getInitialState({
  selectedId: null,
  loading: false,
  loaded: false
});

const reducer = createReducer(
  initialState,

  on(UsersActions.clear, () => initialState),

  on(UsersActions.select, (state, { user: { userId } }) => ({
    ...state,
    selectedId: userId
  })),

  on(UsersActions.subscribe, (state) => ({
    ...state,
    loading: true
  })),

  on(UsersActions.set, (state, { users }) =>
    usersAdapter.setAll(users || [], {
      ...state,
      loading: false,
      loaded: true
    })
  )
);

export function usersReducer(state: UsersState, action: UsersActionsUnion) {
  return reducer(state, action);
}
