import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { auth } from 'firebase/app';
import { of } from 'rxjs';
import { catchError, map, pluck, switchMap } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import { AuthService } from './auth.service';
import { Credential } from './credential.model';
import { User } from './user/user.model';
import { UserService } from './user/user.service';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      pluck('credential'),
      switchMap((credential: Credential) =>
        this.authService.login(credential).pipe(
          map(({ user: { uid, email } }: auth.UserCredential) =>
            AuthActions.loginSuccess({ user: { uid, email } })
          ),
          catchError((error) => of(AuthActions.loginFailure({ error })))
        )
      )
    )
  );

  loginWithFacebook$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithFacebook),
      switchMap(() =>
        this.authService.loginWithFacebook().pipe(
          map(({ user: { uid, email, photoURL, displayName } }: auth.UserCredential) =>
            AuthActions.loginSuccess({ user: { uid, email, photoURL, displayName } })
          ),
          catchError((error) => of(AuthActions.loginFailure({ error })))
        )
      )
    )
  );

  loginWithGoogle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithGoogle),
      switchMap(() =>
        this.authService.loginWithGoogle().pipe(
          map(({ user: { uid, email, photoURL, displayName } }: auth.UserCredential) =>
            AuthActions.loginSuccess({ user: { uid, email, photoURL, displayName } })
          ),
          catchError((error) => of(AuthActions.loginFailure({ error })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      pluck('user'),
      switchMap(({ email }: User) => {
        console.log('userProfile', email);
        return this.userService.getByEmail(email).pipe(
          map((user: User) => AuthActions.updateProfile({ user })),
          catchError((error) => of(AuthActions.loginFailure({ error })))
        );
      })
    )
  );

  // this.authService.login(credential).pipe(
  //   map((userCredential: auth.UserCredential) =>
  //     AuthActions.loginSuccess({

  // @Effect()
  // loginSuccessProfile$ = this.actions$.pipe(
  //   ofType(AuthActionTypes.LoginSuccess),
  //   pluck('payload'),
  //   switchMap(({ user: { uid } }) =>
  //     this.userService.documentChanges({ userId: uid }).pipe(
  //       map((user: User) => new UpdateProfile({ user })),
  //       catchError((error) => of(new LoginFailure(error)))
  //     )
  //   )
  // );

  // @Effect({ dispatch: false })
  // updateProfile$ = this.actions$.pipe(
  //   ofType(AuthActionTypes.UpdateProfile),
  //   tap(() => this.router.navigate(['/']))
  // );

  // @Effect({ dispatch: false })
  // loginRedirect$ = this.actions$.pipe(
  //   ofType(AuthActionTypes.LoginRedirect, AuthActionTypes.Logout),
  //   tap((authed: boolean) => {
  //     this.router.navigate(['/auth', 'login']);
  //   })
  // );

  redirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess, AuthActions.logoutSuccess),
        map(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() => this.authService.logout()),
      map(() => AuthActions.logoutSuccess())
    )
  );

  // @Effect()
  // signUp$: Observable<AuthActionsUnion> = this.actions$.pipe(
  //   ofType(AuthActionTypes.SignUp),
  //   map((action: SignUp) => !!action && action.payload),
  //   switchMap((payload: User) =>
  //     this.authService.signUp(payload).pipe(
  //       map(({ uid }: firebaseUser) => {
  //         return { ...payload, uid };
  //       })
  //     )
  //   ),
  //   map((user: User) => new SignUpSuccess(new User(user))),
  //   catchFailure(new SignUpFailure())
  // );

  // @Effect()
  // deleteAccount$: Observable<AuthActionsUnion> = this.actions$.pipe(
  //   ofType(AuthActionTypes.DeleteAccount),
  //   map((action: DeleteAccount) => !!action && action.payload),
  //   switchMap((payload: User) => this.authService.delete(payload)),
  //   map(() => new DeleteAccountSuccess()),
  //   catchFailure(new DeleteAccountFailure())
  // );

  // @Effect()
  // updateAccount$: Observable<AuthActionsUnion> = this.actions$.pipe(
  //   ofType(AuthActionTypes.UpdateAccount),
  //   map((action: UpdateAccount) => !!action && action.payload),
  //   switchMap((payload) =>
  //     this.authService.update(payload.uid, payload.user).pipe(
  //       map((user) => {
  //         const userUpdate = {
  //           // uid: user.uid,
  //           // emailVerified: user.emailVerified,
  //           // displayName: user.displayName,
  //           // email: user.email,
  //           access: payload.user.access,
  //           password: payload.user.password,
  //           username: payload.user.username
  //         };
  //         return userUpdate;
  //       })
  //     )
  //   ),
  //   map((user: Partial<User>) => new UpdateAccountSuccess(new User(user))),
  //   catchFailure(new UpdateAccountFailure())
  // );

  // @Effect()
  // query$: Observable<Action> = this.actions$.pipe(
  //   ofType(AuthActionTypes.Query),
  //   switchMap(() =>
  //     this.store.pipe(
  //       select(getUserId),
  //       switchMap((userId: string) =>
  //         !!userId
  //           ? this.userService
  //             .documentChanges({ userId })
  //             .pipe(map((user: User) => new UpdateProfile({ user })))
  //           : of(new LogoutSuccess())
  //       )
  //     )
  //   )
  // );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {}
}
