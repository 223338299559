import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { select, Store } from '@ngrx/store';
import { User } from 'firebase';

import { login, loginSuccess, loginWithFacebook, loginWithGoogle } from '../auth.actions';
import * as fromAuth from '../auth.reducer';

@Component({
  selector: 'ptm-auth-login-page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LoginPageComponent implements OnInit {
  pending$ = this.store.pipe(select(fromAuth.getLoginPagePending));
  error$ = this.store.pipe(select(fromAuth.getLoginPageError));

  loginForm: FormGroup;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private store: Store<{}>,
    private router: Router
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: { hidden: true },
        toolbar: { hidden: true },
        footer: { hidden: true },
        sidepanel: { hidden: true }
      }
    };
  }

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  login(form: FormGroup): void {
    this.store.dispatch(login({ credential: form.value }));
  }

  loginWithFacebook(): void {
    this.store.dispatch(loginWithFacebook());
  }

  loginWithGoogle(): void {
    this.store.dispatch(loginWithGoogle());
  }

  success(user: User): void {
    this.store.dispatch(loginSuccess({ user: user?.providerData?.[0] }));

    // TODO: temporary fix to reload page after 2s when profile is retrieved
    setTimeout(() => window.location.reload(), 2000);

    // angular navigate is not working,
    // because authorized routes were not registered at app init
    // this.router.navigateByUrl('/dashboards/project');
  }
}
