import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { PlacesActions } from '../actions';
import { PlacesActionsUnion } from '../actions/places.actions';
import { Place } from '../models';

export interface PlacesState extends EntityState<Place> {
  selectedId: number | string;
  loading: boolean;
  loaded: boolean;
}

export const placesAdapter: EntityAdapter<Place> = createEntityAdapter<Place>({
  selectId: (place: Place) => place?.id,
  sortComparer: (a: Place, b: Place) => a?.name?.localeCompare(b?.name)
});

const initialState: PlacesState = placesAdapter.getInitialState({
  selectedId: null,
  loading: false,
  loaded: false
});

const reducer = createReducer(
  initialState,

  on(PlacesActions.clear, () => initialState),

  on(PlacesActions.select, (state, { place: { placeId } }) => ({
    ...state,
    selectedId: placeId
  })),

  on(PlacesActions.subscribe, (state) => ({
    ...state,
    loading: true
  })),

  on(PlacesActions.set, (state, { places }) =>
    placesAdapter.setAll(places || [], {
      ...state,
      loading: false,
      loaded: true
    })
  )
);

export function placesReducer(state: PlacesState, action: PlacesActionsUnion) {
  return reducer(state, action);
}
