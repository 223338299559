<mat-toolbar>
  <div fxLayout="row"
       fxLayoutAlign="center"
       fxLayoutAlign.gt-xs="end"
       fxFlex>

    <a mat-button [routerLink]="['/documentation', 'changelog']">
      version: {{ version }}
    </a>
    <span>&bull;</span>

    <ng-container *ngIf="colorTheme$ | async as colorTheme">
      <a *ngIf="colorTheme === themeLight" mat-button (click)="changeTheme(themeDark)">Dark Theme</a>
      <a *ngIf="colorTheme === themeDark" mat-button (click)="changeTheme(themeLight)">Light Theme</a>
    </ng-container>
  </div>
</mat-toolbar>
