import { createAction, props, union } from '@ngrx/store';

import { Place } from '../models';

export const clear = createAction('[DB/Places] clear');

export const select = createAction('[DB/Places] select', props<{ place: Partial<Place> }>());

export const set = createAction('[DB/Places] set', props<{ places: Place[] }>());
export const subscribe = createAction('[DB/Places] subscribe');
export const unsubscribe = createAction('[DB/Places] unsubscribe');

const all = union({
  select,
  set,
  subscribe,
  unsubscribe
});
export type PlacesActionsUnion = typeof all;
