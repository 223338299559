export const locale = {
  lang: 'pl',
  data: {
    LOGS: {
      NOTIFICATIONS: 'Powiadomienia',
      MAKE_ALL_READ: 'Oznacz wszystkie jako przeczytane',
      ADD: 'dodał nowego',
      ADD_PLACE: 'dodał nowe',
      ADD_EVENT: 'dodał nowy',
      UPDATE_DETAILS: 'zaktualizował dane',
      REMOVE: 'usunął',
      CLIENT: 'klienta',
      PLACE: 'miejsce',
      EMPLOYEE: 'pracownika',
      EVENT: 'trening',
      AGO: 'temu'
    }
  }
};
