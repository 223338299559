import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Work } from '../models';
import { DbState, getDbState } from '../reducers';
import { worksAdapter, WorksState } from '../reducers/works.reducer';

export const getWorksState = createSelector(getDbState, (state: DbState) => state?.works);

export const {
  selectIds: getWorksIds,
  selectEntities: getWorksEntities,
  selectAll: getWorksAll,
  selectTotal: getWorksTotal
} = worksAdapter.getSelectors(getWorksState);

// export const getWorksError = createSelector(
//   getWorksState,
//   (state: WorksState) => state?.error
// );

export const getWorksSelectedId = createSelector(
  getWorksState,
  (state: WorksState) => state?.selectedId
);

export const getWorksSelected = createSelector(
  getWorksEntities,
  getWorksSelectedId,
  (entities: Dictionary<Work>, selectedId: number) => !!selectedId && entities?.[selectedId]
);
