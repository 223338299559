import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

import { CompaniesService } from '../services';

@Injectable()
export class CompaniesEffects {
  // subscribe$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CompaniesActions.subscribe),
  //     switchMap(() =>
  //       this.companiesService.get().pipe(
  //         takeUntil(this.actions$.pipe(ofType(CompaniesActions.unsubscribe))),
  //         map((companies: Company[]) => CompaniesActions.set({ companies }))
  //       )
  //     )
  //   )
  // );

  constructor(protected actions$: Actions, protected companiesService: CompaniesService) {
    // super(actions$, companyService);
  }
}
