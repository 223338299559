import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ClientsActions } from '../actions';
import { ClientsActionsUnion } from '../actions/clients.actions';
import { Client } from '../models';

export interface ClientsState extends EntityState<Client> {
  selectedId: string;
  loading: boolean;
  loaded: boolean;
}

export const clientsAdapter: EntityAdapter<Client> = createEntityAdapter<Client>({
  selectId: (client: Client) => client?.clientId || client?.createdAt?.toMillis().toString(),
  sortComparer: (a: Client, b: Client) => a?.lastname?.localeCompare(b?.lastname)
});

const initialState: ClientsState = clientsAdapter.getInitialState({
  selectedId: null,
  loading: false,
  loaded: false
});

const reducer = createReducer(
  initialState,

  on(ClientsActions.clear, () => initialState),

  on(ClientsActions.select, (state, { client: { clientId } }) => ({
    ...state,
    selectedId: clientId
  })),

  on(ClientsActions.subscribe, (state) => ({
    ...state,
    loading: true
  })),

  on(ClientsActions.set, (state, { clients }) =>
    clientsAdapter.setAll(clients || [], {
      ...state,
      loading: false,
      loaded: true
    })
  )
);

export function clientsReducer(state: ClientsState, action: ClientsActionsUnion) {
  return reducer(state, action);
}
