import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Client } from '../models';
import { DbState, getDbState } from '../reducers';
import { clientsAdapter, ClientsState } from '../reducers/clients.reducer';

export const getClientsState = createSelector(getDbState, (state: DbState) => state?.clients);

export const {
  selectIds: getClientsIds,
  selectEntities: getClientsEntities,
  selectAll: getClientsAll,
  selectTotal: getClientsTotal
} = clientsAdapter.getSelectors(getClientsState);

// export const getClientsError = createSelector(
//   getClientsState,
//   (state: ClientsState) => state?.error
// );

export const getClientsSelectedId = createSelector(
  getClientsState,
  (state: ClientsState) => state?.selectedId
);

export const getClientsSelected = createSelector(
  getClientsEntities,
  getClientsSelectedId,
  (entities: Dictionary<Client>, selectedId: string) => !!selectedId && entities?.[selectedId]
);
