import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { PlacesActions } from '../actions';

@Injectable()
export class PlaceSelectResolve implements Resolve<void> {
  constructor(private store: Store<{}>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<void> {
    return of(this.store.dispatch(PlacesActions.select({ place: route?.params })));
  }
}
