import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { from, Observable } from 'rxjs';

import 'firebase/auth';

import { Credential } from './credential.model';

@Injectable()
export class AuthService {
  authState$: Observable<firebase.auth.UserCredential>;
  // private createUserApiUrl = `${environment.api}/user/create`;
  // private deleteUserApiUrl = `${environment.api}/user/delete`;
  // private updateUserApiUrl = `${environment.api}/user/update`;

  constructor(
    @Inject('firebaseAuthProject') private afAuth: AngularFireAuth,
    private http: HttpClient
  ) {}

  login(credential: Credential): Observable<firebase.auth.UserCredential> {
    return from(this.afAuth.signInWithEmailAndPassword(credential.email, credential.password));
  }

  loginWithFacebook(): Observable<firebase.auth.UserCredential> {
    const provider = new firebase.auth.FacebookAuthProvider();
    return from(this.afAuth.signInWithPopup(provider));
  }

  loginWithGoogle(): Observable<firebase.auth.UserCredential> {
    const provider = new firebase.auth.GoogleAuthProvider();
    return from(this.afAuth.signInWithPopup(provider));
  }

  logout() {
    return from(this.afAuth.signOut());
  }

  // signUp(user: User): Observable<firebase.User> {
  //   return this.http.post<firebase.User>(this.createUserApiUrl, user);
  // }

  // delete(user: User) {
  //   return this.http.post(this.deleteUserApiUrl, { uid: user.uid });
  // }

  // update(uid: string, user: User): Observable<firebase.auth.UserCredential> {
  //   return this.http.post<firebase.auth.UserCredential>(this.updateUserApiUrl, {
  //     uid,
  //     user
  //   });
  // }
}
