import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';

import { ContentComponent } from './content.component';

@NgModule({
  declarations: [ContentComponent],
  imports: [RouterModule, FuseSharedModule, MatProgressBarModule],
  exports: [ContentComponent]
})
export class ContentModule {}
