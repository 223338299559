import { createAction, props, union } from '@ngrx/store';
import { Client } from 'app/db/models';

export const addClient = createAction(
  '[DB/Company] add client',
  props<{ client: Partial<Client> }>()
);
export const addClientSuccess = createAction(
  '[DB/Company] add client success',
  props<{ company?: any }>()
);
export const addClientFailure = createAction('[DB/Company] add client failure');

export const updateClient = createAction(
  '[DB/Company] update',
  props<{ client: Partial<Client> }>()
);
export const updateClientSuccess = createAction(
  '[DB/Company] update client success',
  props<{ client?: any }>()
);
export const updateClientFailure = createAction('[DB/Company] update client failure');

export const removeClient = createAction(
  '[DB/Company] remove client',
  props<{ client: Partial<Client> }>()
);
export const removeClientSuccess = createAction(
  '[DB/Company] remove client success',
  props<{ client?: Client }>()
);
export const removeClientFailure = createAction('[DB/Company] remove client failure');

const all = union({
  addClient,
  addClientSuccess,
  addClientFailure,
  updateClient,
  updateClientSuccess,
  updateClientFailure,
  removeClient,
  removeClientSuccess,
  removeClientFailure
});
export type CompanyActionsUnion = typeof all;
