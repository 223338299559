<mat-menu #createMenu="matMenu"
          class="calendar-create-box">
  test
  <!-- <ptm-calendar-create-page #createPage
                            *ngIf="start && resourceId"
                            [start]="start"
                            [employeeId]="resourceId"
                            (close)="trigger?.closeMenu()">
  </ptm-calendar-create-page> -->
</mat-menu>
<span [matMenuTriggerFor]="createMenu"></span>
