import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { CompanyConfig, CompanyProfile } from '../models';

@Injectable()
export class CompaniesFormService {
  constructor(private formBuilder: FormBuilder) {}

  profile(profile?: Partial<CompanyProfile>): FormGroup {
    return this.formBuilder.group({
      name: '',
      desc: '',
      logo: '',
      cover: '',
      avatar: '',
      ...profile
    });
  }

  config(config?: Partial<CompanyConfig>): FormGroup {
    return this.formBuilder.group({
      dayEndHour: '',
      dayStartHour: '',
      weekStartsOn: '',
      ...config
    });
  }
}
