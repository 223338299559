import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { CompaniesActions } from '../actions';
import { CompaniesActionsUnion } from '../actions/companies.actions';
import { Company } from '../models';

export interface CompaniesState extends EntityState<Company> {
  selectedId: number | string;
  loading: boolean;
  loaded: boolean;
}

export const companiesAdapter: EntityAdapter<Company> = createEntityAdapter<Company>({
  selectId: (company: Company) => company?.id,
  sortComparer: (a: Company, b: Company) => a?.profile?.name?.localeCompare(b?.profile?.name)
});

const initialState: CompaniesState = companiesAdapter.getInitialState({
  selectedId: null,
  loading: false,
  loaded: false
});

const reducer = createReducer(
  initialState,

  on(CompaniesActions.clear, () => initialState),

  on(CompaniesActions.select, (state, { company: { companyId } }) => ({
    ...state,
    selectedId: companyId
  })),

  on(CompaniesActions.subscribe, (state) => ({
    ...state,
    loading: true
  })),

  on(CompaniesActions.set, (state, { companies }) =>
    companiesAdapter.setAll(companies || [], {
      ...state,
      loading: false,
      loaded: true
    })
  )
);

export function companiesReducer(state: CompaniesState, action: CompaniesActionsUnion) {
  return reducer(state, action);
}
