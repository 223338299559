import { Injectable } from '@angular/core';
import { DocumentReference, DocumentSnapshot } from '@angular/fire/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionType } from '@ngrx/store';
import { LoaderService } from 'app/shared/services/loader.service';
import { from } from 'rxjs';
import { map, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { WorksActions } from '../actions';
import { Work } from '../models';
import { CompanyService, LogsService, WorksService } from '../services';

@Injectable()
export class WorksEffects {
  // subscribe$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(WorksActions.subscribe),
  //     switchMap(() => this.companyService.companyId$),
  //     switchMap((companyId: string) =>
  //       this.worksService.get({ companyId }).pipe(
  //         takeUntil(this.actions$.pipe(ofType(WorksActions.unsubscribe))),
  //         map((works: Work[]) => WorksActions.set({ works }))
  //       )
  //     )
  //   )
  // );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorksActions.add),
      pluck('work'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([workData, companyId]: [Partial<Work>, string]) =>
        this.worksService.add({ companyId, ...workData })
      ),
      switchMap((workRef: DocumentReference) => from(workRef.get())),
      map((work: DocumentSnapshot<Work>) =>
        WorksActions.addSuccess({
          work: {
            id: work?.id,
            ...work?.data()
          }
        })
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorksActions.update),
      pluck('work'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([workData, companyId]: [Partial<Work>, string]) =>
        this.worksService
          .update({ companyId, ...workData, workId: workData?.id })
          .pipe(map((work: DocumentReference) => WorksActions.updateSuccess({ work })))
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorksActions.remove),
      pluck('work'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([workData, companyId]: [Partial<Work>, string]) =>
        this.worksService
          .delete({ companyId, ...workData, workId: workData?.id })
          .pipe(map((work: DocumentReference) => WorksActions.removeSuccess({ work })))
      )
    )
  );

  addUpdateRemoveWorkLog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorksActions.addSuccess, WorksActions.updateSuccess, WorksActions.removeSuccess),
        switchMap(({ work, type }: { work: Work; type: ActionType<Work> }) =>
          this.logsService.addLog({
            object: work,
            type: 'work',
            ...this.getAction(type)
          })
        )
      ),
    { dispatch: false }
  );

  startLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorksActions.add, WorksActions.update, WorksActions.remove),
        tap(() => this.loaderService.isLoading$.next(true))
      ),
    { dispatch: false }
  );

  stopLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          WorksActions.addSuccess,
          WorksActions.addFailure,
          WorksActions.updateSuccess,
          WorksActions.updateFailure,
          WorksActions.removeSuccess,
          WorksActions.removeFailure
        ),
        tap(() => this.loaderService.isLoading$.next(false))
      ),
    { dispatch: false }
  );

  getAction(type: string): { action: string } {
    switch (type) {
      case WorksActions.addSuccess.type:
        return { action: 'add' };
      case WorksActions.updateSuccess.type:
        return { action: 'update' };
      case WorksActions.removeSuccess.type:
        return { action: 'delete' };
    }
  }

  constructor(
    protected actions$: Actions,
    protected worksService: WorksService,
    private companyService: CompanyService,
    private loaderService: LoaderService,
    private logsService: LogsService
  ) {
    // super(actions$, worksService);
  }
}
