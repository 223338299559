import { createFeatureSelector, createSelector } from '@ngrx/store';

import { User } from './user.model';
import * as fromUser from './user.reducer';

export const getUserState = createFeatureSelector<fromUser.State>('users');

export const {
  selectIds: getUserIds,
  selectEntities: getUserEntities,
  selectAll: getAllUsers,
  selectTotal: getTotalUsers
} = fromUser.adapter.getSelectors(getUserState);

export const getUserSelectedId = createSelector(getUserState, fromUser.getSelectedId);

export const getUserSelected = createSelector(
  getUserEntities,
  getUserSelectedId,
  (entities, selectedId) => !!selectedId && entities?.[selectedId]
);
export const getUserSelectedEmail = createSelector(getUserSelected, (user: User) => user?.email);

export const isUserLoaded = createSelector(getUserState, fromUser.isLoaded);

export const isUserLoading = createSelector(getUserState, fromUser.isLoading);

export const existsUser = (userId: string) =>
  createSelector(getUserIds, (ids: string[]) => ids?.indexOf(userId) > -1);

export const getUser = (userId: string) =>
  createSelector(getUserEntities, (entities) => entities?.[userId]);
