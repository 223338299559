import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from 'environments/environment';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';

import { AuthEffects } from './auth.effects';
import { AuthFacade } from './auth.facade';
import { AuthInterceptor } from './auth.interceptor';
import { AuthPageComponent } from './auth.page';
import { reducers } from './auth.reducer';
import { AuthService } from './auth.service';
import { LoginPageComponent } from './login/login.page';
import { LogoutPageComponent } from './logout/logout.page';
import { UserModule } from './user';

@NgModule({
  declarations: [AuthPageComponent, LoginPageComponent, LogoutPageComponent],
  imports: [
    // AuthRoutingModule,
    RouterModule,
    // AngularFireAuthModule,
    StoreModule.forFeature('auth', reducers),
    EffectsModule.forFeature([AuthEffects]),

    FuseSharedModule,
    UserModule,

    NgxAuthFirebaseUIModule.forRoot(environment.firebase, () => 'firebaseui', {
      authGuardFallbackURL: '/',
      authGuardLoggedInURL: '/'
    })
  ],
  providers: [
    AuthFacade,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class AuthModule {}
