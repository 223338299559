import { Injectable } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  CollectionReference,
  DocumentChangeAction,
  DocumentReference,
  Query,
  QueryFn
} from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { from, Observable } from 'rxjs';
import { map, pluck, tap } from 'rxjs/operators';

import { copyObject } from '../utils';

@Injectable()
export abstract class ApiService<T> {
  abstract model: string;

  protected order: { field: string; direction: string } = {
    field: 'createdAt',
    direction: 'desc'
  };

  private get timestamp(): firestore.FieldValue {
    return firestore.FieldValue.serverTimestamp();
  }

  static compare(a: any, b: any): boolean {
    return a.id === b.id;
  }

  static search(term: string, item): boolean {
    return item.searchTerm.indexOf(term.toLocaleLowerCase()) !== -1;
  }

  abstract getCollection(payload: Partial<T>): AngularFirestoreCollection<T>;
  abstract getDocument(payload: Partial<T>): AngularFirestoreDocument<T>;

  collectionChanges(
    payload?: Partial<T>
  ): Observable<{ isInit: boolean; actions: Array<DocumentChangeAction<T>> }> {
    const collection = this.getCollection(payload);
    let isInit = true;
    return (
      !!collection &&
      collection.stateChanges().pipe(
        map((actions) => ({ isInit, actions })),
        tap(() => (isInit = false))
      )
    );
  }

  documentChanges(payload?: Partial<T>): Observable<T> {
    const document = this.getDocument(payload);
    return !!document && document.valueChanges();
  }

  get(payload?: Partial<T>): Observable<T[]> {
    return this.getCollection(payload)
      .snapshotChanges()
      .pipe(
        map((actions: Array<DocumentChangeAction<T>>) =>
          actions.map((action: DocumentChangeAction<T>) => {
            const data = action.payload.doc.data() as T;
            const id = action.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  add(payload: Partial<T>): Observable<DocumentReference> {
    const collection = this.getCollection(payload);
    return (
      !!collection &&
      from(
        collection.add({
          createdAt: this.timestamp,
          updatedAt: this.timestamp,
          ...copyObject(payload)
        })
      )
    );
  }

  set(payload: Partial<T>): Observable<Partial<T>> {
    const document = this.getDocument(payload);
    return (
      !!document &&
      from(
        document.set({
          createdAt: this.timestamp,
          updatedAt: this.timestamp,
          ...copyObject(payload)
        })
      ).pipe(map(() => payload))
    );
  }

  update(payload: Partial<T>): Observable<Partial<T>> {
    const document = this.getDocument(payload);
    return (
      !!document &&
      from(
        document.update({
          ...copyObject(payload),
          updatedAt: this.timestamp
        })
      ).pipe(map(() => payload))
    );
  }

  delete(payload: Partial<T>): Observable<Partial<T>> {
    const document = this.getDocument(payload);
    return !!document && from(document.delete()).pipe(map(() => payload));
  }

  where(
    payload: Partial<T>,
    fieldPath: string | firestore.FieldPath,
    opStr: firestore.WhereFilterOp,
    value: any
  ): Observable<firestore.QueryDocumentSnapshot[]> {
    const collection = this.getCollection(payload);
    return (
      !!collection && from(collection.ref.where(fieldPath, opStr, value).get()).pipe(pluck('docs'))
    );
  }

  protected orderQuery: QueryFn = (ref: CollectionReference): Query =>
    // TODO: remove limit() after optimization
    ref.orderBy(this.order.field, this.order.direction as firestore.OrderByDirection);
  // .limit(100);
}
