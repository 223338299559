import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ActivitiesActions } from '../actions';
import { ActivitiesActionsUnion } from '../actions/activities.actions';
import { Activity } from '../models';

export interface ActivitiesState extends EntityState<Activity> {
  selectedId: number | string;
  loading: boolean;
  loaded: boolean;
}

export const activitiesAdapter: EntityAdapter<Activity> = createEntityAdapter<Activity>({
  selectId: (activity: Activity) => activity?.id,
  sortComparer: (a: Activity, b: Activity) => a?.name?.localeCompare(b?.name)
});

const initialState: ActivitiesState = activitiesAdapter.getInitialState({
  selectedId: null,
  loading: false,
  loaded: false
});

const reducer = createReducer(
  initialState,

  on(ActivitiesActions.clear, () => initialState),

  on(ActivitiesActions.select, (state, { activity: { activityId } }) => ({
    ...state,
    selectedId: activityId
  })),

  on(ActivitiesActions.subscribe, (state) => ({
    ...state,
    loading: true
  })),

  on(ActivitiesActions.set, (state, { activities }) =>
    activitiesAdapter.setAll(activities || [], {
      ...state,
      loading: false,
      loaded: true
    })
  )
);

export function activitiesReducer(state: ActivitiesState, action: ActivitiesActionsUnion) {
  return reducer(state, action);
}
