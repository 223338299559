import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { WorksActions } from '../actions';
import { WorksActionsUnion } from '../actions/works.actions';
import { Work } from '../models';

export interface WorksState extends EntityState<Work> {
  selectedId: number | string;
  loading: boolean;
  loaded: boolean;
}

export const worksAdapter: EntityAdapter<Work> = createEntityAdapter<Work>({
  selectId: (work: Work) => work?.id
  // sortComparer: (a: Work, b: Work) =>
  //   a?.name?.localeCompare(b?.name)
});

const initialState: WorksState = worksAdapter.getInitialState({
  selectedId: null,
  loading: false,
  loaded: false
});

const reducer = createReducer(
  initialState,

  on(WorksActions.clear, () => initialState),

  on(WorksActions.select, (state, { work: { workId } }) => ({
    ...state,
    selectedId: workId
  })),

  on(WorksActions.subscribe, (state) => ({
    ...state,
    loading: true
  })),

  on(WorksActions.set, (state, { works }) =>
    worksAdapter.setAll(works || [], {
      ...state,
      loading: false,
      loaded: true
    })
  )
);

export function worksReducer(state: WorksState, action: WorksActionsUnion) {
  return reducer(state, action);
}
