import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  CollectionReference,
  Query
} from '@angular/fire/firestore';
import { ApiService } from 'app/shared/services/api.service';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

import { Event } from '../models';

@Injectable()
export class EventsService extends ApiService<Event> {
  model = 'Event';
  order = { field: 'start', secondField: 'place.id', direction: 'asc' };

  // TODO: overwrite with company's config
  // static config = config.company.events.breakpoint;

  // static getBreakpoint(date: Date): Date {
  //   if (!!EventService.config.subHours) {
  //     date = subHours(date, EventService.config.subHours);
  //   }
  //   if (!!EventService.config.hours) {
  //     date = setHours(date, EventService.config.hours);
  //   }
  //   if (!!EventService.config.subDays) {
  //     date = subDays(date, EventService.config.subDays);
  //   }
  //   return date;
  // }

  // static isCancelable({ start }: Partial<Event>): boolean {
  //   const date = (start as firestore.Timestamp).toDate();
  //   return new Date() < EventService.getBreakpoint(date);
  // }

  // static createId({ name, lastname }: Event, nextId?: number): string {
  //   return slugify(`${lastname}-${name}${nextId > 1 ? nextId : ''}`);
  // }

  static search(term: string, item: Partial<Event>): boolean {
    return false;
    // return super.search(term, new Event(item));
  }

  constructor(@Inject('firebaseEvents') private afs: AngularFirestore) {
    super();
  }

  getCollection(): AngularFirestoreCollection<Event> {
    return this.afs.collection<Event>('events', this.orderQuery);
  }

  getDocument({ id }): AngularFirestoreDocument<Event> {
    return this.getCollection().doc<Event>(id);
  }

  getFromToPlace(from: string, to: string, placeId: string): Observable<Event[]> {
    return this.afs
      .collection<Event>(
        'events',
        (ref: CollectionReference): Query =>
          ref
            .orderBy(this.order.field, this.order.direction as firestore.OrderByDirection)
            .where(this.order.field, '>=', new Date(from))
            .where(this.order.field, '<=', new Date(to))
            .where(this.order.secondField, '==', placeId)
      )
      .valueChanges({ idField: 'id' })
      .pipe(
        tap((events) => console.log('events', events)),
        shareReplay()
      );
  }

  getByEmployee(employeeId: string = 'sojkowski-marcin', from: any, to: any): Observable<Event[]> {
    return this.afs
      .collection<Event>(
        'events',
        (ref: CollectionReference): Query =>
          ref
            .orderBy(this.order.field, this.order.direction as firestore.OrderByDirection)
            .where(this.order.field, '>=', new Date(from))
            .where(this.order.field, '<=', new Date(to))
            .where('employee.id', '==', employeeId)
      )
      .valueChanges({ idField: 'id' })
      .pipe(shareReplay());
  }

  getByClient(clientId: string = 'sojkowski-marcin', from: any, to: any): Observable<Event[]> {
    return this.afs
      .collection<Event>(
        'events',
        (ref: CollectionReference): Query =>
          ref
            .orderBy(this.order.field, this.order.direction as firestore.OrderByDirection)
            .where(this.order.field, '>=', new Date(from))
            .where(this.order.field, '<=', new Date(to))
            .where('client.id', '==', clientId)
      )
      .valueChanges({ idField: 'id' })
      .pipe(shareReplay());
  }
}
