import { createAction, props, union } from '@ngrx/store';

import { Employee } from '../models';

export const clear = createAction('[DB/Employees] clear');

export const select = createAction(
  '[DB/Employees] select',
  props<{ employee: Partial<Employee> }>()
);

export const set = createAction('[DB/Employees] set', props<{ employees: Employee[] }>());
export const subscribe = createAction('[DB/Employees] subscribe');
export const unsubscribe = createAction('[DB/Employees] unsubscribe');

export const add = createAction('[DB/Employees] add', props<{ employee: Partial<Employee> }>());
export const addSuccess = createAction(
  '[DB/Employees] add success',
  props<{ employee?: Partial<Employee> }>()
);
export const addFailure = createAction('[DB/Employees] add failure', props<{ error?: any }>());

export const update = createAction(
  '[DB/Employees] update',
  props<{ employee: Partial<Employee> }>()
);
export const updateSuccess = createAction(
  '[DB/Employees] update success',
  props<{ employee?: Partial<Employee> }>()
);
export const updateFailure = createAction(
  '[DB/Employees] update failure',
  props<{ error?: any }>()
);

export const remove = createAction(
  '[DB/Employees] remove',
  props<{ employee: Partial<Employee> }>()
);
export const removeSuccess = createAction(
  '[DB/Employees] remove success',
  props<{ employee?: Partial<Employee> }>()
);
export const removeFailure = createAction(
  '[DB/Employees] remove failure',
  props<{ error?: any }>()
);

const all = union({
  select,
  set,
  subscribe,
  unsubscribe,

  add,
  addSuccess,
  addFailure,

  update,
  updateSuccess,
  updateFailure,

  remove,
  removeSuccess,
  removeFailure
});
export type EmployeesActionsUnion = typeof all;
