import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EditButtonDirective } from './edit-button.directive';
import { EditModeDirective } from './edit-mode.directive';
import { EditableComponent } from './editable.component';
import { ViewModeDirective } from './view-mode.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [EditableComponent, EditButtonDirective, EditModeDirective, ViewModeDirective],
  exports: [EditableComponent, EditButtonDirective, EditModeDirective, ViewModeDirective]
})
export class EditableModule {}
