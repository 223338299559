import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Vacation } from '../models';
import { DbState, getDbState } from '../reducers';
import { vacationsAdapter, VacationsState } from '../reducers/vacations.reducer';

export const getVacationsState = createSelector(getDbState, (state: DbState) => state?.vacations);

export const {
  selectIds: getVacationsIds,
  selectEntities: getVacationsEntities,
  selectAll: getVacationsAll,
  selectTotal: getVacationsTotal
} = vacationsAdapter.getSelectors(getVacationsState);

// export const getVacationsError = createSelector(
//   getVacationsState,
//   (state: VacationsState) => state?.error
// );

export const getVacationsSelectedId = createSelector(
  getVacationsState,
  (state: VacationsState) => state?.selectedId
);

export const getVacationsSelected = createSelector(
  getVacationsEntities,
  getVacationsSelectedId,
  (entities: Dictionary<Vacation>, selectedId: number) => !!selectedId && entities?.[selectedId]
);
