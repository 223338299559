import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentReference
} from '@angular/fire/firestore';
import { Client, Company, Transaction } from 'app/db/models';
import { CompanyService } from 'app/db/services/company.service';
import { UserService } from 'app/db/services/user.service';
import { UserInfo } from 'firebase';
import { combineLatest, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { ClientsFacade } from '../facades/clients.facade';

@Injectable()
export class TransactionsService {
  constructor(
    @Inject('firebaseProject') private afs: AngularFirestore,
    private userService: UserService,
    private clientsFacade: ClientsFacade,
    private companyService: CompanyService
  ) {}

  clientId$: Observable<string> = this.clientsFacade.selectedId$;
  client$: Observable<Client> = this.clientsFacade.selected$;
  user$: Observable<UserInfo> = this.userService.user$;
  company$: Observable<Company> = this.companyService.company$;

  addTransaction(transaction: Partial<Transaction>): Observable<DocumentReference> {
    return combineLatest([this.user$, this.clientId$]).pipe(
      first(),
      switchMap(([user, clientId]) => {
        const author = this.createAuthor(user);
        return this.getCollection('transactions', clientId).add({
          author,
          ...transaction,
          createdAt: new Date(Date.now())
        });
      })
    );
  }

  getAllTransactionsFromTo(startDate?: Date, endDate?: Date): Observable<any> {
    return this.clientId$.pipe(
      first(),
      switchMap((cliendId: string) =>
        this.getCollection('transactions', cliendId, startDate, endDate).valueChanges()
      )
    );
  }

  private createAuthor(user: UserInfo): { id: string; displayName: string } {
    return { id: user.uid, displayName: user.displayName };
  }

  private getDocument(collection: string, uid: string): AngularFirestoreDocument<any> {
    return this.afs.doc<any>(`${collection}/${uid}`);
  }

  private getCollection(
    collection: string,
    uid: string,
    startDate?: Date,
    endDate: Date = new Date(Date.now())
  ): AngularFirestoreCollection<any> {
    return this.getDocument(collection, uid).collection<any>(collection, (ref) => {
      const query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      return (startDate
        ? query.where('createdAt', '>', startDate).where('createdAt', '<', endDate)
        : query
      ).orderBy('createdAt', 'asc');
    });
  }
}
