import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable()
export class BillsFormService {
  constructor(private formBuilder: FormBuilder) {}

  discount(): FormGroup {
    return this.formBuilder.group({
      discount: 0
    });
  }
}
