import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { format, formatDistance, formatRelative } from 'date-fns';

import { CalendarEvent } from '../../calendar-event.model';

@Component({
  selector: 'calendar-event',
  templateUrl: './event.component.html',
  styleUrls: ['event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CalendarEventComponent {
  @Input() event: CalendarEvent;
  @Output() remove = new EventEmitter<Event>();
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  constructor(public translate: TranslateService) {}

  get id(): string | number {
    return this.event.id;
  }

  get title(): string {
    return this.event.title;
  }

  get meta() {
    // @ts-ignore
    return this.event.extendedProps.meta || {};
  }

  get client() {
    return this.meta.client || {};
  }

  get employee() {
    return this.meta.employee || {};
  }

  get start() {
    return this.meta.start.toDate();
  }
  get startTime() {
    return !!this.start && format(this.start, 'HH:mm');
  }
  get startRelative() {
    return !!this.start && formatRelative(this.start, new Date());
  }
  get end() {
    return this.meta.end.toDate();
  }
  get endTime() {
    return !!this.end && format(this.end, 'HH:mm');
  }
  get time() {
    return `${this.startTime} - ${this.endTime}`;
  }

  get url(): string[] {
    return ['/manager', 'clients', this.client.id];
  }

  get tooltip(): string {
    return `${this.client.id} ${this.client.lastname || ''}`;
  }

  get updatedAt(): Date {
    return !!this.meta && this.meta.updatedAt.toDate();
  }

  get updatedAtRelative(): string {
    return !!this.updatedAt && formatDistance(this.updatedAt, new Date());
  }
}
