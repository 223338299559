import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CompanyEffects } from 'app/db/effects/company.effects';

import {
  ActivitiesEffects,
  ClientsEffects,
  CompaniesEffects,
  EmployeesEffects,
  EventsEffects,
  PlacesEffects,
  UsersEffects,
  VacationsEffects,
  WorksEffects
} from './effects';
import {
  ActivitiesFacade,
  ClientsFacade,
  CompaniesFacade,
  EmployeesFacade,
  EventsFacade,
  PlacesFacade,
  UsersFacade,
  VacationsFacade,
  WorksFacade
} from './facades';
import { reducers } from './reducers';
import {
  ActivitiesResolve,
  ClientSelectResolve,
  ClientsResolve,
  CompaniesResolve,
  EmployeeSelectResolve,
  EmployeesResolve,
  EventsResolve,
  PlaceSelectResolve,
  PlacesResolve,
  UsersResolve,
  VacationsResolve,
  WorksResolve
} from './resolvers';
import {
  ActivitiesService,
  BillsFormService,
  BillsService,
  ClientsFormService,
  ClientsService,
  CompaniesFormService,
  CompaniesService,
  CompanyService,
  EmployeesFormService,
  EmployeesService,
  EventsFormService,
  EventsService,
  LogsService,
  PlacesService,
  TransactionsService,
  UserService,
  UsersService,
  VacationsFormService,
  VacationsService,
  WorksFormService,
  WorksService
} from './services';

@NgModule({
  imports: [
    StoreModule.forFeature('db', reducers),
    EffectsModule.forFeature([
      ActivitiesEffects,
      ClientsEffects,
      CompaniesEffects,
      EmployeesEffects,
      EventsEffects,
      PlacesEffects,
      UsersEffects,
      VacationsEffects,
      WorksEffects,
      CompanyEffects
    ])
  ],
  providers: [
    ActivitiesFacade,
    ActivitiesResolve,
    ActivitiesService,
    ClientsFacade,
    ClientsResolve,
    ClientsFormService,
    ClientsService,
    ClientSelectResolve,
    CompanyService,
    CompaniesFacade,
    CompaniesResolve,
    CompaniesFormService,
    CompaniesService,
    EmployeesFacade,
    EmployeesResolve,
    EmployeesFormService,
    EmployeesService,
    EmployeeSelectResolve,
    EventsFacade,
    EventsResolve,
    EventsFormService,
    EventsService,
    PlacesFacade,
    PlacesResolve,
    PlacesService,
    PlaceSelectResolve,
    UserService,
    UsersFacade,
    UsersResolve,
    UsersService,
    VacationsFacade,
    VacationsResolve,
    VacationsFormService,
    VacationsService,
    WorksFacade,
    WorksResolve,
    WorksFormService,
    WorksService,
    BillsFormService,
    BillsService,
    TransactionsService,
    LogsService
  ]
})
export class DbModule {}
