import { createAction, props, union } from '@ngrx/store';

import { User } from '../models';

export const clear = createAction('[DB/Users] clear');

export const select = createAction('[DB/Users] select', props<{ user: Partial<User> }>());

export const set = createAction('[DB/Users] set', props<{ users: User[] }>());
export const subscribe = createAction('[DB/Users] subscribe');
export const unsubscribe = createAction('[DB/Users] unsubscribe');

const all = union({
  select,
  set,
  subscribe,
  unsubscribe
});
export type UsersActionsUnion = typeof all;
