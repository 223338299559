import { createReducer, on } from '@ngrx/store';
import { md5 } from 'app/shared/utils';

import * as AuthActions from './auth.actions';
import { User } from './user';

export interface State {
  user: User | null;
}

export const initState: State = {
  user: null
};

const reducer = createReducer(
  initState,

  on(AuthActions.login, AuthActions.loginFailure, (state) => ({
    ...state,
    user: null
  })),

  on(AuthActions.loginSuccess, AuthActions.updateProfile, (state, { user }) => ({
    ...state,
    user: {
      ...user,
      photoURL: user?.photoURL || `https://gravatar.com/avatar/${md5(user.email)}`
    }
  })),

  on(AuthActions.logout, () => initState)
);

export function authReducer(state: State, action: AuthActions.AuthActionsUnion) {
  return reducer(state, action);
}
