import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAuthStatus from './auth-status.reducer';
import { AuthActionsUnion } from './auth.actions';
import * as fromLoginPage from './login/login.reducer';
import { User } from './user';

export interface AuthState {
  status: fromAuthStatus.State;
  loginPage: fromLoginPage.State;
}

export interface State {
  auth: AuthState;
}

export const reducers: ActionReducerMap<AuthState, AuthActionsUnion> = {
  status: fromAuthStatus?.authReducer,
  loginPage: fromLoginPage?.loginReducer
};

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state: AuthState) => state?.status
);
export const getUser = createSelector(selectAuthStatusState, (state) => state?.user);
export const getLoggedIn = createSelector(getUser, (user: User) => !!user);
export const getUserAccess = createSelector(getUser, (user: User) => user?.access);

export const getUserId = createSelector(getUser, (user: User) => user?.uid);

export const selectLoginPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state?.loginPage
);
export const getLoginPageError = createSelector(selectLoginPageState, fromLoginPage.getError);
export const getLoginPagePending = createSelector(selectLoginPageState, fromLoginPage.getPending);

export const isUserAdmin = createSelector(getUser, (user: User) => user?.isAdmin);

export const getReadNotificationsDate = createSelector(
  getUser,
  (user: User) => user?.readNotificationsDate
);
