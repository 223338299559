import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ActivitiesActions } from '../actions';
import { Activity } from '../models';
import {
  getActivitiesAll,
  getActivitiesIds,
  getActivitiesSelected,
  getActivitiesSelectedId
} from '../selectors/activities.selectors';

@Injectable({ providedIn: 'root' })
export class ActivitiesFacade {
  all$: Observable<Activity[]> = this.store.pipe(select(getActivitiesAll));

  ids$: Observable<number[] | string[]> = this.store.pipe(select(getActivitiesIds));

  selected$: Observable<Activity> = this.store.pipe(select(getActivitiesSelected));

  selectedId$: Observable<number | string> = this.store.pipe(select(getActivitiesSelectedId));

  constructor(private store: Store<{}>) {}

  clear(): void {
    this.store.dispatch(ActivitiesActions.clear());
  }

  select(activity: Partial<Activity>): void {
    this.store.dispatch(ActivitiesActions.select({ activity }));
  }

  subscribe(): void {
    this.store.dispatch(ActivitiesActions.subscribe());
  }

  unsubscribe(): void {
    this.store.dispatch(ActivitiesActions.unsubscribe());
  }
}
