import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { VacationsActions } from '../actions';
import { VacationsActionsUnion } from '../actions/vacations.actions';
import { Vacation } from '../models';

export interface VacationsState extends EntityState<Vacation> {
  selectedId: number | string;
  loading: boolean;
  loaded: boolean;
}

export const vacationsAdapter: EntityAdapter<Vacation> = createEntityAdapter<Vacation>({
  selectId: (vacation: Vacation) => vacation?.id
  // sortComparer: (a: Vacation, b: Vacation) =>
  //   a?.name?.localeCompare(b?.name)
});

const initialState: VacationsState = vacationsAdapter.getInitialState({
  selectedId: null,
  loading: false,
  loaded: false
});

const reducer = createReducer(
  initialState,

  on(VacationsActions.clear, () => initialState),

  on(VacationsActions.select, (state, { vacation: { vacationId } }) => ({
    ...state,
    selectedId: vacationId
  })),

  on(VacationsActions.subscribe, (state) => ({
    ...state,
    loading: true
  })),

  on(VacationsActions.set, (state, { vacations }) =>
    vacationsAdapter.setAll(vacations || [], {
      ...state,
      loading: false,
      loaded: true
    })
  )
);

export function vacationsReducer(state: VacationsState, action: VacationsActionsUnion) {
  return reducer(state, action);
}
