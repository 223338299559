import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Place } from '../models';
import { DbState, getDbState } from '../reducers';
import { placesAdapter, PlacesState } from '../reducers/places.reducer';

export const getPlacesState = createSelector(getDbState, (state: DbState) => state?.places);

export const {
  selectIds: getPlacesIds,
  selectEntities: getPlacesEntities,
  selectAll: getPlacesAll,
  selectTotal: getPlacesTotal
} = placesAdapter.getSelectors(getPlacesState);

// export const getPlacesError = createSelector(
//   getPlacesState,
//   (state: PlacesState) => state?.error
// );

export const getPlacesSelectedId = createSelector(
  getPlacesState,
  (state: PlacesState) => state?.selectedId
);

export const getPlacesSelected = createSelector(
  getPlacesEntities,
  getPlacesSelectedId,
  (entities: Dictionary<Place>, selectedId: number) => !!selectedId && entities?.[selectedId]
);

export const getPlacesForCalendar = createSelector(
  getPlacesAll,
  // getEventFilterPlaceId,
  (places) =>
    places?.map((place: Place) => ({
      id: place?.id,
      title: place?.name,
      meta: place
    }))
  // .filter((place: Place) => place.id
  //   title: place.name
  // }))
);
