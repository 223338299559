import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EventsActions } from '../actions';
import { EventsActionsUnion } from '../actions/events.actions';
import { Event } from '../models';

export interface EventsState extends EntityState<Event> {
  selectedId: number | string;
  loading: boolean;
  loaded: boolean;
}

export const eventsAdapter: EntityAdapter<Event> = createEntityAdapter<Event>({
  selectId: (event: Event) => event?.id
  // sortComparer: (a: Event, b: Event) =>
  //   a?.name?.localeCompare(b?.name)
});

const initialState: EventsState = eventsAdapter.getInitialState({
  selectedId: null,
  loading: false,
  loaded: false
});

const reducer = createReducer(
  initialState,

  on(EventsActions.clear, () => initialState),

  on(EventsActions.select, (state, { event: { eventId } }) => ({
    ...state,
    selectedId: eventId
  })),

  on(EventsActions.subscribe, (state) => ({
    ...state,
    loading: true
  })),

  on(EventsActions.set, (state, { events }) =>
    eventsAdapter.setAll(events || [], {
      ...state,
      loading: false,
      loaded: true
    })
  )
);

export function eventsReducer(state: EventsState, action: EventsActionsUnion) {
  return reducer(state, action);
}
