<mat-toolbar class="p-0 mat-elevation-z1">

  <div fxFlex
       fxFill
       fxLayout="row"
       fxLayoutAlign="start center">

    <div fxFlex="1 0 auto"
         fxLayout="row"
         fxLayoutAlign="start center">

      <button mat-icon-button
              class="navbar-toggle-button"
              *ngIf="!hiddenNavbar && !rightNavbar"
              (click)="toggleSidebarOpen('navbar')"
              fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div class="toolbar-separator"
           *ngIf="!hiddenNavbar && !rightNavbar"
           fxHide.gt-md></div>

      <div fxLayout="row"
           fxLayoutAlign="start center"
           *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon"
               src="assets/images/logos/fuse.svg">
        </div>
      </div>

      <!-- <div class="px-8 px-md-16">
        <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
      </div> -->

    </div>

    <div class=""
         fxFlex="0 1 auto"
         fxLayout="row"
         fxLayoutAlign="start center">

      <ngx-auth-firebaseui-avatar class="userbar"></ngx-auth-firebaseui-avatar>
      <div class="toolbar-separator"></div>

      <company-switcher></company-switcher>
      <div class="toolbar-separator"></div>

      <fuse-search-bar (input)="search($event)"></fuse-search-bar>
      <div class="toolbar-separator"></div>

      <button mat-button
              fxHide
              fxShow.gt-xs
              class="language-button"
              [matMenuTriggerFor]="languageMenu">
        <div fxLayout="row"
             fxLayoutAlign="center center">
          <img class="flag mr-8"
               [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
          <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
        </div>
      </button>

      <mat-menu #languageMenu="matMenu"
                [overlapTrigger]="false">

        <button mat-menu-item
                *ngFor="let lang of languages"
                (click)="setLanguage(lang)">
          <span fxLayout="row"
                fxLayoutAlign="start center">
            <img class="flag mr-16"
                 [src]="'assets/icons/flags/'+lang.flag+'.png'">
            <span class="iso">{{lang.title}}</span>
          </span>
        </button>

        <button mat-menu-item
                [routerLink]="'/components/multi-language'">
          <div fxLayout="row"
               fxLayoutAlign="start center">
            <span>Learn more</span>
          </div>
        </button>

      </mat-menu>

      <div class="toolbar-separator"
           fxHide
           fxShow.gt-xs></div>

      <button mat-icon-button
              fxHide.gt-md
              class="chat-panel-toggle-button"
              (click)="toggleSidebarOpen('chatPanel')"
              aria-label="Toggle chat panel">
        <mat-icon class="secondary-text">chat</mat-icon>
      </button>
      <ng-container *ngIf="{ status: logsStatus$ | async } as logs">
        <div class="toolbar-separator"
             fxHide.gt-md></div>
        <button mat-icon-button
                class="logs-toggle-button"
                aria-label="Toggle logs"
                [matMenuTriggerFor]="menu"
                #triggerButton="matMenuTrigger"
                (click)="toggleLogs()">
          <mat-icon [matBadge]="logs?.status?.unread"
                    [matBadgeHidden]="logs?.status?.unread === 0"
                    matBadgeColor="warn"
                    class="secondary-text">notifications</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <div class="logs-menu">
            <div (click)="$event.stopPropagation();">
              <logs [logs]="logs$ | async"
                    [logsStatus]="logs?.status"
                    (closeLogsComponent)="triggerButton.closeMenu()"></logs>
            </div>
          </div>
        </mat-menu>
      </ng-container>
      <div class="
             toolbar-separator"
           *ngIf="!hiddenNavbar && rightNavbar"
           fxHide
           fxShow.gt-xs></div>

      <button mat-icon-button
              class="navbar-toggle-button"
              *ngIf="!hiddenNavbar && rightNavbar"
              (click)="toggleSidebarOpen('navbar')"
              fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
