<div *ngIf="!items?.length"
     fxLayout="column"
     fxLayoutAlign="center center"
     fxFlexFill>
  <span class="no-results-text hint-text">
    {{ noResultsText }}
  </span>
</div>

<div class="list">
  <cdk-virtual-scroll-viewport [itemSize]="220/3"
                               class="list-viewport">
    <ng-container
                  *cdkVirtualFor="let item of items; trackBy: trackBy(trackProperty)">
      <ng-container
                    *ngTemplateOutlet="itemTemplate; context: { $implicit: item }">
      </ng-container>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</div>
