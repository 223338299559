import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { WorksActions } from '../actions';

@Injectable()
export class WorksResolve implements Resolve<void> {
  constructor(private store: Store<{}>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<void> {
    return of(this.store.dispatch(WorksActions.subscribe()));
  }
}
