import { createAction, props, union } from '@ngrx/store';

import { Client } from '../models';

export const clear = createAction('[DB/Clients] clear');

export const select = createAction('[DB/Clients] select', props<{ client: Partial<Client> }>());

export const set = createAction('[DB/Clients] set', props<{ clients: Client[] }>());
export const subscribe = createAction('[DB/Clients] subscribe');
export const unsubscribe = createAction('[DB/Clients] unsubscribe');

export const add = createAction('[DB/Clients] add', props<{ client: Partial<Client> }>());
export const addSuccess = createAction(
  '[DB/Clients] add success',
  props<{ client?: Partial<Client> }>()
);
export const addFailure = createAction('[DB/Clients] add failure');

export const update = createAction('[DB/Clients] update', props<{ client: Partial<Client> }>());
export const updateSuccess = createAction(
  '[DB/Clients] update success',
  props<{ client?: Partial<Client> }>()
);
export const updateFailure = createAction('[DB/Clients] update failure');

export const remove = createAction('[DB/Clients] remove', props<{ client: Partial<Client> }>());
export const removeSuccess = createAction(
  '[DB/Clients] remove success',
  props<{ client?: Client }>()
);
export const removeFailure = createAction('[DB/Clients] remove failure');

const all = union({
  select,
  set,
  subscribe,
  unsubscribe,
  add,
  addSuccess,
  addFailure,
  update,
  updateSuccess,
  updateFailure,
  remove,
  removeSuccess,
  removeFailure
});
export type ClientsActionsUnion = typeof all;
