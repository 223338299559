import { Injectable } from '@angular/core';
import { DocumentReference, DocumentSnapshot } from '@angular/fire/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionType } from '@ngrx/store';
import { LoaderService } from 'app/shared/services/loader.service';
import { from } from 'rxjs';
import { map, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ClientsActions } from '../actions';
import { Client } from '../models';
import { ClientsService, CompanyService, LogsService } from '../services';

@Injectable()
export class ClientsEffects {
  // subscribe$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ClientsActions.subscribe),
  //     switchMap(() => this.companyService.companyId$),
  //     switchMap((companyId: string) =>
  //       this.clientsService.get({ companyId }).pipe(
  //         takeUntil(this.actions$.pipe(ofType(ClientsActions.unsubscribe))),
  //         map((clients: Client[]) => ClientsActions.set({ clients }))
  //       )
  //     )
  //   )
  // );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActions.add),
      pluck('client'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([clientData, companyId]: [Partial<Client>, string]) =>
        this.clientsService.add({
          companyId,
          ...clientData,
          slug: ClientsService.createId(clientData)
        })
      ),
      switchMap((clientRef: DocumentReference) => from(clientRef.get())),
      map((client: DocumentSnapshot<Client>) =>
        ClientsActions.addSuccess({
          client: {
            ...client?.data(),
            id: client?.id
          }
        })
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActions.update),
      pluck('client'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([clientData, companyId]: [Partial<Client>, string]) =>
        this.clientsService
          .update({ companyId, ...clientData, clientId: clientData?.id })
          .pipe(map((client: DocumentReference) => ClientsActions.updateSuccess({ client })))
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActions.remove),
      pluck('client'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([clientData, companyId]: [Client, string]) =>
        this.clientsService
          .delete({
            ...clientData,
            companyId,
            clientId: clientData?.id
          })
          .pipe(map((client: Client) => ClientsActions.removeSuccess({ client })))
      )
    )
  );

  addUpdateDeleteClientLog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ClientsActions.addSuccess,
          ClientsActions.updateSuccess,
          ClientsActions.removeSuccess
        ),
        switchMap(({ client, type }: { client: Client; type: ActionType<Client> }) =>
          this.logsService.addLog({
            object: client,
            type: 'client',
            ...this.getAction(type)
          })
        )
      ),
    { dispatch: false }
  );

  startLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClientsActions.add, ClientsActions.update, ClientsActions.remove),
        tap(() => this.loaderService.isLoading$.next(true))
      ),
    { dispatch: false }
  );
  stopLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ClientsActions.addSuccess,
          ClientsActions.addFailure,
          ClientsActions.updateSuccess,
          ClientsActions.updateFailure,
          ClientsActions.removeSuccess,
          ClientsActions.removeFailure
        ),
        tap(() => this.loaderService.isLoading$.next(false))
      ),
    { dispatch: false }
  );

  getAction(type: string): { action: string } {
    switch (type) {
      case ClientsActions.addSuccess.type:
        return { action: 'add' };
      case ClientsActions.updateSuccess.type:
        return { action: 'update' };
      case ClientsActions.removeSuccess.type:
        return { action: 'delete' };
    }
  }

  constructor(
    protected actions$: Actions,
    protected clientsService: ClientsService,
    private companyService: CompanyService,
    private loaderService: LoaderService,
    private logsService: LogsService
  ) {
    // super(actions$, clientsService);
  }
}
