import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { LogsModule } from 'app/layout/components/toolbar/logs/logs.module';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';

import { CompanySwitcherModule } from '../company-switcher/company-switcher.module';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatBadgeModule,

    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,

    NgxAuthFirebaseUIModule,
    CompanySwitcherModule,
    LogsModule
  ],
  exports: [ToolbarComponent]
})
export class ToolbarModule {}
