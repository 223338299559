import { Injectable } from '@angular/core';
import { Timestamp } from '@firebase/firestore-types';
import { createSelector, select, Store } from '@ngrx/store';
import { Employee, EmployeesFacade, Event, Place, Time, Vacation, Work } from 'app/db';
import { getEmployeesAll, getPlacesAll } from 'app/db/selectors';
import { EventsService, VacationsService, WorksService } from 'app/db/services';
import { CalendarEvent } from 'app/shared/calendar';
import { basicInfo } from 'app/shared/utils';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { CalendarResource } from './calendar-resource.model';

@Injectable({ providedIn: 'root' })
export class CalendarService {
  resources$: Observable<CalendarResource[]>;
  eventsFilterPlace$: BehaviorSubject<string> = new BehaviorSubject(null);
  eventsFilterDate$: BehaviorSubject<any> = new BehaviorSubject(null);
  works$: Observable<Work[]> = this.worksService.works$;
  employees$: Observable<Employee[]> = this.employeesFacade.fromStore$;

  events$: Observable<CalendarEvent[]> = combineLatest([
    this.eventsFilterPlace$,
    this.eventsFilterDate$
  ]).pipe(
    filter(([placeId, date]) => !!date),
    switchMap(([placeId, date]) =>
      this.eventsService
        .getFromToPlace(date?.startStr, date?.endStr, placeId)
        .pipe(map((events: Event[]) => this.eventsMapping(events)))
    )
  );

  vacations$: Observable<Vacation[]> = this.eventsFilterDate$.pipe(
    filter<{ startStr: string; endStr: string }>(Boolean),
    switchMap((date) =>
      this.vacationsService.getFromPreviousToNextMonth(date?.startStr, date?.endStr)
    )
  );

  eventsAll$: Observable<CalendarEvent[]> = combineLatest([
    this.events$,
    this.works$,
    this.vacations$,
    this.employees$
  ]).pipe(
    map(([events, works, vacations, employees]) => [
      ...this.vacationsMapping(vacations, employees),
      ...this.worksMapping(works, employees),
      ...events
    ])
  );

  getCalendarPlaces = createSelector(
    getPlacesAll,
    // getEventFilterPlaceId,
    (places) =>
      places
        // .filter((place: Place) => place.id
        //   title: place.name
        // }))
        .map((place: Place) => ({
          id: place?.id,
          title: place?.name,
          meta: place
        }))
  );

  getCalendarEmployees = createSelector(getEmployeesAll, (employees) =>
    employees.map((employee: Employee) => ({
      id: employee?.id,
      title: employee?.alias || employee?.name,
      meta: employee,
      eventColor: employee?.color,
      parentId: employee?.place?.id
    }))
  );

  getCalendarResources = createSelector(
    this.getCalendarPlaces,
    this.getCalendarEmployees,
    // getEventFilterPlaceId,
    (places, employees, filterPlaceId = null) =>
      [...places, ...employees].filter(
        (resource: CalendarResource) =>
          !filterPlaceId ||
          (!!filterPlaceId &&
            (resource?.id === filterPlaceId || resource?.parentId === filterPlaceId))
      )
  );

  vacationsMapping(vacations: Vacation[], employees: Employee[]): CalendarEvent[] {
    return vacations.map((vacation: Vacation) => ({
      start: new Date((vacation?.start as Timestamp).seconds * 1000),
      end: new Date((vacation?.end as Timestamp).seconds * 1000),
      resourceId: vacation?.employeeId,
      parentId: employees.find((employee) => employee?.id === vacation?.employeeId)?.place?.id,
      display: 'background',
      backgroundColor: '#ff9f89',
      overlap: false
    }));
  }

  worksMapping(works: Work[], employees: Employee[]): CalendarEvent[] {
    return works.map((work: Work) => ({
      daysOfWeek: [work?.day],
      startTime: new Time(work?.start).toString(),
      endTime: new Time(work?.end).toString(),
      employeeId: work?.employeeId,
      resourceId: work?.employeeId as string,
      parentId: employees.find((employee) => employee?.id === work?.employeeId)?.place?.id,
      display: 'background',
      color: '#8fdf82',
      eventColor: '#8fdf82',
      backgroundColor: '#8fdf82'
    }));
  }
  eventsMapping(events: Event[]): CalendarEvent[] {
    return events.map((event: Event) => {
      const client = event?.client;
      const title = !!client ? `${client?.name} ${client?.lastname}` : '';
      return {
        id: event?.createdAt?.toMillis().toString(),
        title,
        meta: event,
        start: event?.start.toDate(),
        end: event?.end.toDate(),
        resourceId: (event?.employee?.id || event?.employee) as string,
        parentId: (event?.place?.id || event?.place) as string
      };
    });
  }

  getEventEmployee(event, employee): Partial<Employee> {
    if (employee) {
      return { ...basicInfo(employee?.extendedProps?.meta), id: employee?.id };
    }
    return event?.extendedProps?.meta?.employee;
  }

  constructor(
    private store: Store<{}>,
    private eventsService: EventsService,
    private vacationsService: VacationsService,
    private worksService: WorksService,
    private employeesFacade: EmployeesFacade
  ) {
    this.resources$ = this.store.pipe(select(this.getCalendarResources));
  }
}
