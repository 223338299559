import { createAction, props, union } from '@ngrx/store';

import { Vacation } from '../models';

export const clear = createAction('[DB/Vacations] clear');

export const select = createAction(
  '[DB/Vacations] select',
  props<{ vacation: Partial<Vacation> }>()
);

export const set = createAction('[DB/Vacations] set', props<{ vacations: Vacation[] }>());
export const subscribe = createAction('[DB/Vacations] subscribe');
export const unsubscribe = createAction('[DB/Vacations] unsubscribe');

export const add = createAction('[DB/Vacations] add', props<{ vacation: Partial<Vacation> }>());
export const addSuccess = createAction(
  '[DB/Vacations] add success',
  props<{ vacation?: Partial<Vacation> }>()
);
export const addFailure = createAction('[DB/Vacations] add failure');

export const update = createAction(
  '[DB/Vacations] update',
  props<{ vacation: Partial<Vacation> }>()
);
export const updateSuccess = createAction(
  '[DB/Vacations] update success',
  props<{ vacation?: Partial<Vacation> }>()
);
export const updateFailure = createAction('[DB/Vacations] update failure');

export const remove = createAction(
  '[DB/Vacations] remove',
  props<{ vacation: Partial<Vacation> }>()
);
export const removeSuccess = createAction(
  '[DB/Vacations] remove success',
  props<{ vacation?: Partial<Vacation> }>()
);
export const removeFailure = createAction('[DB/Vacations] remove failure');

const all = union({
  select,
  set,
  subscribe,
  unsubscribe,
  add,
  addSuccess,
  addFailure,
  update,
  updateSuccess,
  updateFailure,
  remove,
  removeSuccess,
  removeFailure
});
export type VacationsActionsUnion = typeof all;
