import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { UserActionsUnion, UserActionTypes } from './user.actions';
import { User } from './user.model';

export interface State extends EntityState<User> {
  selectedId: string;
  loaded: boolean;
  loading: boolean;
}

export function sortByEmail(a: User, b: User): number {
  return a.email.localeCompare(b.email);
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (client: User) => client.uid,
  sortComparer: sortByEmail
});

export const initState: State = adapter.getInitialState({
  selectedId: null,
  loaded: false,
  loading: false
});

export function reducer(state: State = initState, action: UserActionsUnion) {
  switch (action.type) {
    case UserActionTypes.Subscribe:
      return {
        ...state,
        loading: true
      };

    case UserActionTypes.Set:
      return adapter.addAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case UserActionTypes.Select:
      return { ...state, selectedId: action.payload.userId };

    default:
      return state;
  }
}

export const getSelectedId = (state: State): string => state.selectedId;
export const isLoaded = (state: State): boolean => state.loaded;
export const isLoading = (state: State): boolean => state.loading;
