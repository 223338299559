import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Activity } from '../models';
import { DbState, getDbState } from '../reducers';
import { activitiesAdapter, ActivitiesState } from '../reducers/activities.reducer';

export const getActivitiesState = createSelector(getDbState, (state: DbState) => state?.activities);

export const {
  selectIds: getActivitiesIds,
  selectEntities: getActivitiesEntities,
  selectAll: getActivitiesAll,
  selectTotal: getActivitiesTotal
} = activitiesAdapter.getSelectors(getActivitiesState);

// export const getActivitiesError = createSelector(
//   getActivitiesState,
//   (state: ActivitiesState) => state?.error
// );

export const getActivitiesSelectedId = createSelector(
  getActivitiesState,
  (state: ActivitiesState) => state?.selectedId
);

export const getActivitiesSelected = createSelector(
  getActivitiesEntities,
  getActivitiesSelectedId,
  (entities: Dictionary<Activity>, selectedId: number) => !!selectedId && entities?.[selectedId]
);
