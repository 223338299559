import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDistance } from 'date-fns';
import { pl } from 'date-fns/locale';

@Pipe({
  name: 'distanceInWordsTranslate',
  pure: false
})
export class DistanceInWordsTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(date: Date): string {
    return formatDistance(
      date,
      Date.now(),
      this.translateService.currentLang === 'pl' ? { locale: pl } : null
    );
  }
}
