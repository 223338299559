import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PlacesActions } from '../actions';
import { Place } from '../models';
import {
  getPlacesAll,
  getPlacesIds,
  getPlacesSelected,
  getPlacesSelectedId
} from '../selectors/places.selectors';

@Injectable({ providedIn: 'root' })
export class PlacesFacade {
  all$: Observable<Place[]> = this.store.pipe(select(getPlacesAll));

  ids$: Observable<number[] | string[]> = this.store.pipe(select(getPlacesIds));

  selected$: Observable<Place> = this.store.pipe(select(getPlacesSelected));

  selectedId$: Observable<number | string> = this.store.pipe(select(getPlacesSelectedId));

  constructor(private store: Store<{}>) {}

  clear(): void {
    this.store.dispatch(PlacesActions.clear());
  }

  select(place: Partial<Place>): void {
    this.store.dispatch(PlacesActions.select({ place }));
  }

  subscribe(): void {
    this.store.dispatch(PlacesActions.subscribe());
  }

  unsubscribe(): void {
    this.store.dispatch(PlacesActions.unsubscribe());
  }
}
