import { Injectable } from '@angular/core';
import { DocumentReference, DocumentSnapshot } from '@angular/fire/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionType } from '@ngrx/store';
import { LoaderService } from 'app/shared/services/loader.service';
import { from } from 'rxjs';
import { map, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { EventsActions } from '../actions';
import { Event } from '../models';
import { CompanyService, EventsService, LogsService } from '../services';

@Injectable()
export class EventsEffects {
  // subscribe$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(EventsActions.subscribe),
  //     switchMap(() => this.companyService.companyId$),
  //     switchMap((companyId: string) =>
  //       this.eventsService.get({ companyId }).pipe(
  //         takeUntil(this.actions$.pipe(
  //           ofType(EventsActions.unsubscribe)
  //         )),
  //         map((events: Event[]) =>
  //           EventsActions.set({
  //             events: events.filter(({ start }: Event) =>
  //               !!start && typeof start !== 'string'
  //             ).map((event: Event) => ({
  //               ...event,
  //               startDate: event.start?.toDate(),
  //               endDate: event.end?.toDate(),
  //               createdAtDate: event.createdAt?.toDate(),
  //               updatedAtDate: event.updatedAt?.toDate()
  //             }))
  //           })
  //         ),
  //       )
  //     )
  //   )
  // );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.add),
      pluck('event'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([eventData, companyId]: [Partial<Event>, string]) =>
        this.eventsService.add({ companyId, ...eventData })
      ),
      switchMap((eventRef: DocumentReference) => from(eventRef.get())),
      map((event: DocumentSnapshot<Event>) =>
        EventsActions.addSuccess({
          event: {
            id: event?.id,
            ...event?.data()
          }
        })
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.update),
      pluck('event'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([eventData, companyId]: [Partial<Event>, string]) =>
        this.eventsService
          .update({ companyId, ...eventData, id: eventData?.id })
          .pipe(map((event: DocumentReference) => EventsActions.updateSuccess({ event })))
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.remove),
      pluck('event'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([eventData, companyId]: [Event, string]) =>
        this.eventsService
          .delete({
            ...eventData,
            companyId,
            eventId: eventData?.id
          })
          .pipe(map((event: Event) => EventsActions.removeSuccess({ event })))
      )
    )
  );

  addUpdateDeleteEventLog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventsActions.addSuccess, EventsActions.updateSuccess, EventsActions.removeSuccess),
        switchMap(({ event, type }: { event: Event; type: ActionType<Event> }) =>
          this.logsService.addLog(
            {
              object: event,
              type: 'event',
              ...this.getAction(type)
            },
            [event?.client?.userId, event?.employee?.userId]
          )
        )
      ),
    { dispatch: false }
  );

  startLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventsActions.add, EventsActions.update, EventsActions.remove),
        tap(() => this.loaderService.isLoading$.next(true))
      ),
    { dispatch: false }
  );

  stopLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EventsActions.addSuccess,
          EventsActions.addFailure,
          EventsActions.updateSuccess,
          EventsActions.updateFailure,
          EventsActions.removeSuccess,
          EventsActions.removeFailure
        ),
        tap(() => this.loaderService.isLoading$.next(false))
      ),
    { dispatch: false }
  );

  getAction(type: string): { action: string } {
    switch (type) {
      case EventsActions.addSuccess.type:
        return { action: 'add' };
      case EventsActions.updateSuccess.type:
        return { action: 'update' };
      case EventsActions.removeSuccess.type:
        return { action: 'delete' };
    }
  }

  constructor(
    protected actions$: Actions,
    protected eventsService: EventsService,
    private companyService: CompanyService,
    private loaderService: LoaderService,
    private logsService: LogsService
  ) {
    // super(actions$, eventsService);
  }
}
