import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { WorksActions } from '../actions';
import { Work } from '../models';
import {
  getWorksAll,
  getWorksIds,
  getWorksSelected,
  getWorksSelectedId
} from '../selectors/works.selectors';

@Injectable({ providedIn: 'root' })
export class WorksFacade {
  all$: Observable<Work[]> = this.store.pipe(select(getWorksAll));

  ids$: Observable<number[] | string[]> = this.store.pipe(select(getWorksIds));

  selected$: Observable<Work> = this.store.pipe(select(getWorksSelected));

  selectedId$: Observable<number | string> = this.store.pipe(select(getWorksSelectedId));

  constructor(private store: Store<{}>) {}

  clear(): void {
    this.store.dispatch(WorksActions.clear());
  }

  select(work: Partial<Work>): void {
    this.store.dispatch(WorksActions.select({ work }));
  }

  subscribe(): void {
    this.store.dispatch(WorksActions.subscribe());
  }

  unsubscribe(): void {
    this.store.dispatch(WorksActions.unsubscribe());
  }

  add(work: Partial<Work>): void {
    this.store.dispatch(WorksActions.add({ work }));
  }

  update(work: Partial<Work>): void {
    this.store.dispatch(WorksActions.update({ work }));
  }

  remove(work: Partial<Work>): void {
    this.store.dispatch(WorksActions.remove({ work }));
  }
}
