import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Log } from 'app/db/models/log.model';
import { LogsService } from 'app/db/services';
import { trackBy } from 'app/shared/utils';

import { locale as english } from './i18n/en';
import { locale as polish } from './i18n/pl';

enum Actions {
  add = 'add',
  update = 'update',
  delete = 'delete'
}

@Component({
  selector: 'logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  @Input() logs: Log[];
  @Input() logsStatus: any;
  @Output() closeLogsComponent = new EventEmitter<void>();

  trackBy = trackBy;
  Actions = Actions;

  constructor(
    private logsService: LogsService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, polish);
  }

  setAllLogsRead(): void {
    this.logsService.setStatus({ status: { readDate: new Date(Date.now()) } }).toPromise();
  }

  ngOnInit(): void {}
}
