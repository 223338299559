import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

import { trackBy } from 'app/shared/utils';

@Component({
  selector: 'list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  @Input() items: any[];
  @Input() trackProperty: string;
  @Input() noResultsText: string;
  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;

  trackBy = trackBy;
}
