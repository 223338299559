import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { getUserId } from 'app/main/auth';

import { UsersActions } from '../actions';
import { User, UserAccess } from '../models';
import {
  getUsersAll,
  getUsersIds,
  getUsersSelected,
  getUsersSelectedAccess,
  getUsersSelectedAccessInfo,
  getUsersSelectedIsAdmin
} from '../selectors/users.selectors';

@Injectable({ providedIn: 'root' })
export class UsersFacade {
  all$: Observable<User[]> = this.store.pipe(select(getUsersAll));

  ids$: Observable<number[] | string[]> = this.store.pipe(select(getUsersIds));

  selected$: Observable<User> = this.store.pipe(select(getUsersSelected));

  selectedIsAdmin$: Observable<boolean> = this.store.pipe(select(getUsersSelectedIsAdmin));

  selectedAccess$: Observable<UserAccess[]> = this.store.pipe(select(getUsersSelectedAccess));

  selectedAccessInfo$: Observable<UserAccess[]> = this.store.pipe(
    select(getUsersSelectedAccessInfo)
  );

  selectedId$: Observable<string> = this.store.pipe(select(getUserId));

  constructor(private store: Store<{}>) {}

  clear(): void {
    this.store.dispatch(UsersActions.clear());
  }

  select(user: Partial<User>): void {
    this.store.dispatch(UsersActions.select({ user }));
  }

  subscribe(): void {
    this.store.dispatch(UsersActions.subscribe());
  }

  unsubscribe(): void {
    this.store.dispatch(UsersActions.unsubscribe());
  }
}
