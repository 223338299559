import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { ApiService } from 'app/shared/services/api.service';

import { Company } from '../models';

@Injectable()
export class CompaniesService extends ApiService<Company> {
  model = 'Company';

  static search(term: string, item: Partial<Company>): boolean {
    return false;
    // return super.search(term, new Company(item));
  }

  constructor(@Inject('firebaseProject') private afs: AngularFirestore) {
    super();
  }

  getCollection(): AngularFirestoreCollection<Company> {
    return this.afs.collection<Company>('companies', this.orderQuery);
  }

  getDocument({ companyId }): AngularFirestoreDocument<Company> {
    return this.getCollection().doc<Company>(companyId);
  }
}
