import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { CompanyService } from 'app/db/services/company.service';
import { ApiService } from 'app/shared/services/api.service';
import { slugify } from 'app/shared/utils';
import { Observable } from 'rxjs';
import { filter, shareReplay, switchMap, tap } from 'rxjs/operators';

import { Company, Place } from '../models';
import { CompaniesService } from './companies.service';

@Injectable()
export class PlacesService extends ApiService<Company> {
  places$: Observable<Place[]> = this.companyService.companyId$.pipe(
    filter(Boolean),
    switchMap((companyId: string) =>
      this.afs.collection<Place>(`companies/${companyId}/places`).valueChanges({ idField: 'id' })
    ),
    tap(() => console.log('places')),
    shareReplay()
  );

  model = 'Place';
  order = { field: 'name', direction: 'asc' };

  static createId({ name }: Place, nextId?: number): string {
    return slugify(`${name}${nextId > 1 ? nextId : ''}`);
  }

  static search(term: string, item: Partial<Company>): boolean {
    return false;
    // return super.search(term, new Company(item));
  }

  constructor(
    @Inject('firebaseProject') private afs: AngularFirestore,
    private companiesService: CompaniesService,
    private companyService: CompanyService
  ) {
    super();
  }

  getCollection({ companyId }): AngularFirestoreCollection<Place> {
    return this.companiesService
      .getDocument({ companyId })
      .collection<Place>('places', this.orderQuery);
  }

  getDocument({ companyId, placeId }): AngularFirestoreDocument<Place> {
    return this.getCollection({ companyId }).doc<Place>(placeId);
  }

  getPlace(placeId: string): Observable<Place> {
    return this.companyService.companyId$.pipe(
      filter(Boolean),
      switchMap((companyId: string) =>
        this.afs.doc<Place>(`companies/${companyId}/places/${placeId}`).valueChanges()
      ),
      shareReplay()
    );
  }
}
