import { createReducer, on } from '@ngrx/store';

import * as AuthActions from '../auth.actions';

export interface State {
  error: string | null;
  pending: boolean;
}

export const initState: State = {
  error: null,
  pending: false
};

const reducer = createReducer(
  initState,

  on(AuthActions.login, (state) => ({
    ...state,
    error: null,
    pending: true
  })),

  on(AuthActions.loginSuccess, (state) => ({
    ...state,
    error: null,
    pending: false
  })),

  on(AuthActions.loginFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false
  })),

  on(AuthActions.logout, () => initState)
);

export function loginReducer(state: State, action: AuthActions.AuthActionsUnion) {
  return reducer(state, action);
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
