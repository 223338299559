import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  CollectionReference,
  Query,
  QueryFn
} from '@angular/fire/firestore';
import { CompanyService } from 'app/db/services/company.service';
import { ApiService } from 'app/shared/services/api.service';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { filter, shareReplay, switchMap } from 'rxjs/operators';

import { Work } from '../models';
import { CompaniesService } from './companies.service';

@Injectable()
export class WorksService extends ApiService<Work> {
  works$: Observable<Work[]> = this.companyService.companyId$.pipe(
    filter(Boolean),
    switchMap((companyId: string) =>
      this.afs.collection<Work>(`companies/${companyId}/works`).valueChanges({ idField: 'id' })
    ),
    shareReplay()
  );

  model = 'Work';
  order = { field: 'day', direction: 'asc' };

  static search(term: string, item: Partial<Work>): boolean {
    return false;
  }

  constructor(
    private companiesService: CompaniesService,
    private companyService: CompanyService,
    @Inject('firebaseProject') private afs: AngularFirestore
  ) {
    super();
  }

  getCollection({ companyId }): AngularFirestoreCollection<Work> {
    return this.companiesService
      .getDocument({ companyId })
      .collection<Work>('works', this.orderQuery);
  }

  getDocument({ companyId, workId }): AngularFirestoreDocument<Work> {
    return this.getCollection({ companyId }).doc<Work>(workId);
  }

  orderQuery: QueryFn = (ref: CollectionReference): Query =>
    // TODO: remove limit() after optimization
    ref
      .orderBy(this.order.field, this.order.direction as firestore.OrderByDirection)
      .orderBy('start', 'asc');
  // .limit(100);
}
