export const locale = {
  lang: 'en',
  data: {
    NAV: {
      DASHBOARD: 'Dashboard',
      CALENDAR: 'Calendar',
      SCHEDULE: 'Schedule',
      MANAGER: 'Manager',
      CLIENTS: 'Clients',
      EMPLOYEES: 'Employees',
      PLACES: 'Places',
      SETTINGS: 'Settings'
    }
  }
};
