<!--
    *ngIf="true" hack is required here for router-outlet to work
    correctly. Otherwise, it won't register the changes on the
    layout and won't update the view.
 -->
<div class="loader-container"
     *ngIf="loaderService?.isLoading$ | async">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<router-outlet *ngIf="true"></router-outlet>
