import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable()
export class EmployeesFormService {
  constructor(private formBuilder: FormBuilder) {}

  profile(): FormGroup {
    return this.formBuilder.group({
      id: '',
      name: '',
      lastname: '',
      photo: '',
      alias: '',
      email: '',
      // place: '',
      phone: ''
    });
  }
}
