import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ClientsService } from 'app/db/services/clients.service';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { ClientsActions } from '../actions';
import { Client } from '../models';
import { getClientsIds, getClientsSelectedId } from '../selectors/clients.selectors';

@Injectable({ providedIn: 'root' })
export class ClientsFacade {
  all$: Observable<Client[]> = this.clientsService.clients$;

  ids$: Observable<number[] | string[]> = this.store.pipe(select(getClientsIds));

  selected$: Observable<Client> = this.store.pipe(
    select(getClientsSelectedId),
    switchMap(
      (clientId: string) => (!!clientId && this.clientsService.getClient(clientId)) || of(null)
    ),
    shareReplay()
  );

  selectedId$: Observable<string> = this.store.pipe(select(getClientsSelectedId));

  constructor(private store: Store<{}>, private clientsService: ClientsService) {}

  clear(): void {
    this.store.dispatch(ClientsActions.clear());
  }

  select(client: Partial<Client>): void {
    this.store.dispatch(ClientsActions.select({ client }));
  }

  subscribe(): void {
    this.store.dispatch(ClientsActions.subscribe());
  }

  unsubscribe(): void {
    this.store.dispatch(ClientsActions.unsubscribe());
  }

  add(client: Partial<Client>): void {
    this.store.dispatch(ClientsActions.add({ client }));
  }

  update(client: Partial<Client>): void {
    this.store.dispatch(ClientsActions.update({ client }));
  }

  remove(client: Partial<Client>): void {
    this.store.dispatch(ClientsActions.remove({ client }));
  }
}
