import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CalendarResource } from '../../calendar-resource.model';

@Component({
  selector: 'calendar-resource',
  templateUrl: './resource.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarResourceComponent {
  resource: CalendarResource;

  get isEmployee(): boolean {
    return !!this.meta.lastname;
  }

  get isPlace(): boolean {
    return !this.meta.lastname;
  }

  get type(): 'employees' | 'places' {
    return this.isEmployee ? 'employees' : 'places';
  }

  get id(): string {
    return this.resource.id;
  }

  get title(): string {
    return this.resource.title;
  }

  get meta() {
    return this.resource.extendedProps.meta || {};
  }

  get url(): string[] {
    return ['/manager', this.type, this.id];
  }

  get tooltip(): string {
    return `${this.meta.name} ${this.meta.lastname || ''}`;
  }

  get photo(): string {
    return this.meta.photo;
  }
}
