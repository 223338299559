import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { CompanyService } from 'app/db/services/company.service';
import { ApiService } from 'app/shared/services/api.service';
import { slugify } from 'app/shared/utils';
import { Observable } from 'rxjs';
import { filter, map, pluck, shareReplay, switchMap, tap } from 'rxjs/operators';

import { Client } from '../models';
import { CompaniesService } from './companies.service';

@Injectable()
export class ClientsService extends ApiService<Client> {
  clients$: Observable<Client[]> = this.companyService.company$.pipe(
    filter(Boolean),
    pluck<Client[], null>('clients'),
    tap(() => console.log('clients')),
    shareReplay()
  );

  model = 'Client';
  order = { field: 'lastname', direction: 'asc' };

  static createId({ name, lastname }: Partial<Client>, nextId?: number): string {
    return slugify(`${lastname}-${name}${nextId > 1 ? nextId : ''}`);
  }

  static search(term: string, item: Partial<Client>): boolean {
    return `${item.name}${item.lastname}`.toLowerCase().indexOf(term.toLocaleLowerCase()) !== -1;
    // return super.search(term, new Client(item));
  }

  constructor(
    private companiesService: CompaniesService,
    private companyService: CompanyService,
    @Inject('firebaseProject') private afs: AngularFirestore
  ) {
    super();
  }

  getCollection({ companyId }): AngularFirestoreCollection<Client> {
    return this.companiesService
      .getDocument({ companyId })
      .collection<Client>('clients', this.orderQuery);
  }

  getDocument({ companyId, clientId }): AngularFirestoreDocument<Client> {
    return this.getCollection({ companyId }).doc<Client>(clientId);
  }

  getClient(id: string): Observable<Client> {
    return this.companyService.companyId$.pipe(
      filter(Boolean),
      switchMap((companyId: string) =>
        this.afs.doc<Client>(`companies/${companyId}/clients/${id}`).valueChanges()
      ),
      map((client: Client) => ({ ...client, id })),
      tap(() => console.log('single client')),
      shareReplay()
    );
  }
}
