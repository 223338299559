import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Work } from '../models';

@Injectable()
export class WorksFormService {
  constructor(private formBuilder: FormBuilder) {}

  work(work?: Partial<Work>): FormGroup {
    return this.formBuilder.group({
      day: '',
      start: '',
      end: '',
      employeeId: '',
      ...work
    });
  }
}
