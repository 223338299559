import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Company } from '../models';
import { DbState, getDbState } from '../reducers';
import { companiesAdapter, CompaniesState } from '../reducers/companies.reducer';

export const getCompaniesState = createSelector(getDbState, (state: DbState) => state?.companies);

export const {
  selectIds: getCompaniesIds,
  selectEntities: getCompaniesEntities,
  selectAll: getCompaniesAll,
  selectTotal: getCompaniesTotal
} = companiesAdapter.getSelectors(getCompaniesState);

// export const getCompaniesError = createSelector(
//   getCompaniesState,
//   (state: CompaniesState) => state?.error
// );

export const getCompaniesSelectedId = createSelector(
  getCompaniesState,
  (state: CompaniesState) => state?.selectedId
);

export const getCompaniesSelected = createSelector(
  getCompaniesEntities,
  getCompaniesSelectedId,
  (entities: Dictionary<Company>, selectedId: number) => !!selectedId && entities?.[selectedId]
);
