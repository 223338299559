import * as ActivitiesActions from './activities.actions';
import * as ClientsActions from './clients.actions';
import * as CompaniesActions from './companies.actions';
import * as CompanyActions from './company.actions';
import * as EmployeesActions from './employees.actions';
import * as EventsActions from './events.actions';
import * as PlacesActions from './places.actions';
import * as UsersActions from './users.actions';
import * as VacationsActions from './vacations.actions';
import * as WorksActions from './works.actions';

export {
  ActivitiesActions,
  ClientsActions,
  CompaniesActions,
  EmployeesActions,
  EventsActions,
  PlacesActions,
  UsersActions,
  VacationsActions,
  WorksActions,
  CompanyActions
};
