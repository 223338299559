/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 */
const typeCache: { [label: string]: boolean } = {};
export const type = <T>(label: T | ''): T => {
  if (typeCache[label as string]) {
    throw new Error(`Action type "${label}" is not unqiue"`);
  }
  typeCache[label as string] = true;
  return label as T;
};

export const actionTypeSuccess = 'success';
export const actionTypeFailure = 'failure';

export const baseActionTypes = (model: string) => ({
  Set: type(`[${model}] set`),
  Subscribe: type(`[${model}] subscribe`),
  Unsubscribe: type(`[${model}] unsubscribe`),

  Query: type(`[${model}] query`),

  Create: type(`[${model}] create`),
  CreateSuccess: type(`[${model}] create ${actionTypeSuccess}`),
  CreateFailure: type(`[${model}] create ${actionTypeFailure}`),

  Update: type(`[${model}] update`),
  UpdateSuccess: type(`[${model}] update ${actionTypeSuccess}`),
  UpdateFailure: type(`[${model}] update ${actionTypeFailure}`),

  Delete: type(`[${model}] delete`),
  DeleteSuccess: type(`[${model}] delete ${actionTypeSuccess}`),
  DeleteFailure: type(`[${model}] delete ${actionTypeFailure}`),

  Select: type(`[${model}] select`),
  SelectSuccess: type(`[${model}] select ${actionTypeSuccess}`),
  SelectFailure: type(`[${model}] select ${actionTypeFailure}`)
});
