import { addDays, formatDistance, isDate } from 'date-fns';
import { pl } from 'date-fns/locale';
import { firestore } from 'firebase/app';
import { Observable, OperatorFunction } from 'rxjs';
import { Md5 } from 'ts-md5';

import { latinize } from './latinize';

export const getSubdomain = () => location.host.split('.')[0];

export const getObjectKeys = (obj: {}) => !!obj && Object.keys(obj);
export const getPropsArray = (obj: {}) => !!obj && getObjectKeys(obj).map((key) => obj[key]);
export const getPropsArrayWithKeys = (obj: {}, prop: string = '_key') =>
  !!obj && getObjectKeys(obj).map((key) => ({ ...obj[key], [prop]: key }));

export const slugify = (text: string) =>
  latinize(text.toString())
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '');

export const createPassword = () => {
  const length = 6;
  const charset = 'abcdefghjkpqrstuxyz23456789';
  let text = '';

  for (let i = 0, n = charset.length; i < length; ++i) {
    text += charset.charAt(Math.floor(Math.random() * n));
  }
  return text;
};

export const hourPattern = '^[0-1]?[0-9]$|^[2][0-3]$';
export const minutesPattern = '^[0-5]?[0-9]$';

export const forEachDay = (start, end) => {
  const days = [];
  for (let d = start; d <= end; d = addDays(d, 1)) {
    days.push(new Date(d));
  }
  return days;
};

export const getDateLocale = (locale: string) => {
  if (locale === 'pl') {
    return pl;
  }
};

export const distanceInWordsToNowWithLocale = (date: Date, locale: string): string => {
  return formatDistance(date, new Date(), {
    locale: getDateLocale(locale)
  });
};

export const dateToTimestamp = (date: Date) => firestore.Timestamp.fromDate(date);

export const daysNumArray = Array.from(new Array(7), (x, i) => i);

export const daysNameArray = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const removeEmpty = (obj) =>
  Object.keys(obj)
    .filter((k) => k !== 'nextId' && obj[k] !== null && obj[k] !== undefined)
    .reduce(
      (newObj, k) =>
        typeof obj[k] === 'object' && !isDate(obj[k])
          ? Object.assign(newObj, { [k]: removeEmpty(obj[k]) })
          : Object.assign(newObj, { [k]: obj[k] }),
      {}
    );

export const concat = (x, y) => x.concat(y);
export const checkDate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/;
export const dateReviver = (key, value) =>
  checkDate.test(value) ? new Date(value) : timestampReviver(key, value);
export const timestampReviver = (key, value) =>
  !!value && value.hasOwnProperty('nanoseconds')
    ? firestore.Timestamp.fromDate(new Date(value.seconds * 1000 + value.nanoseconds))
    : value;
export const copyObject = (obj) => JSON.parse(JSON.stringify(removeEmpty(obj)), dateReviver);
export const flatMap = (xs, f) => xs.map(f).reduce(concat, []);

export const catchFailure = <T>(action?): OperatorFunction<T, T> => (
  source$: Observable<T>
): Observable<T> =>
  new Observable<T>((observer) =>
    source$.subscribe({
      next: (value) => observer.next(value),
      error: (error) => {
        console.error('Store/effect: Firebase error', error);
        observer.error(action ? action : error);
      },
      complete: () => observer.complete()
    })
  );

export const md5 = (value: string): string => new Md5().appendStr(value).end().toString();

export const trackBy = (property: string) => (index: number, item: any) => item?.[property];

export const timeToString = (hours: number, minutes: number): string =>
  `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

export const basicInfo = ({ id = '', name = '', lastname = '', userId = '' } = {}) => ({
  id,
  name,
  lastname,
  userId
});

export const getAuthor = ({ uid = '', displayName = '', photoURL = '' } = {}) => ({
  uid,
  displayName,
  photoURL
});
