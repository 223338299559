import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CalendarEvent } from 'app/shared/calendar';
import { basicInfo } from 'app/shared/utils';
import * as moment from 'moment';

import { Event } from '../models';

@Injectable()
export class EventsFormService {
  constructor(private formBuilder: FormBuilder) {}

  static toCalendar(event: Event): CalendarEvent {
    return {
      id: event.id,
      title: `${event.client?.name} ${event.client?.lastname}`,
      // description: '',
      meta: event,
      start: event.start.toDate(),
      // !!event.start && (event.start as Timestamp).toDate(),
      end: event.end.toDate(),
      // !!event.end && (event.end as Timestamp).toDate(),
      resourceId: event.employee?.id,
      parentId: event.place?.id
      // constraint: 'businessHours'
    };
  }

  static toCalendarMany(events: Event[]): CalendarEvent[] {
    return events.map((event: Event) => this.toCalendar(event));
  }

  static formToPayload(form: any): Event {
    const start: moment.Moment = moment(form.date);
    // if (!moment.isMoment(form.date)) {
    start.hours(form.time.substr(0, 2));
    start.minutes(form.time.substr(3, 2));

    return {
      ...form,
      start: start.toDate(),
      end: start.add(60, 'm').toDate(),
      employee: basicInfo(form.employee),
      client: basicInfo(form.client),
      place: basicInfo(form.place)
    };
  }

  event(event?: Partial<Event>): FormGroup {
    return this.formBuilder.group({
      activity: '',
      client: '',
      employee: '',
      place: '',
      start: '',
      end: '',
      date: '',
      time: '',
      ...event
    });
  }
}
