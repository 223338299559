import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { basicInfo } from 'app/shared/utils';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { Client, Company } from '../models';

@Injectable()
export class CompanyService {
  private path = 'companies/';

  // TODO: get company based on subdomain/app config?
  companyId$: Observable<string> = of('egobody');
  // this.userService.access$.pipe(
  //   map((access: UserAccess) => Object.keys(access)?.[0])
  // );

  company$: Observable<Company> = this.companyId$.pipe(
    switchMap((companyId: string) =>
      this.afs.doc<Company>(`${this.path}${companyId}`).valueChanges()
    ),
    shareReplay()
  );

  addClient({
    client,
    clients,
    companyId
  }: {
    client: Partial<Client>;
    clients: Client[];
    companyId: string;
  }): Promise<void> {
    return this.afs
      .doc<any>(`${this.path}${companyId}`)
      .update({ clients: [...clients, basicInfo(client)] });
  }

  updateClient({
    client,
    clients,
    companyId
  }: {
    client: Partial<Client>;
    clients: Client[];
    companyId: string;
  }): Promise<void> {
    return this.afs.doc<any>(`${this.path}${companyId}`).update({
      clients: [...clients.filter((clientItem) => clientItem.id !== client?.id), basicInfo(client)]
    });
  }

  deleteClient({
    client,
    clients,
    companyId
  }: {
    client: Partial<Client>;
    clients: Client[];
    companyId: string;
  }): Promise<void> {
    return this.afs.doc<any>(`${this.path}${companyId}`).update({
      clients: [...clients.filter((clientItem) => clientItem.id !== client?.id)]
    });
  }

  constructor(@Inject('firebaseProject') private afs: AngularFirestore) {}
}
