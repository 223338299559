<a [routerLink]="url" [matTooltip]="tooltip" style="text-align: center; display: inline-block; width: 40px; max-width: 100%;">
  <!-- <img *ngIf="photo" [src]="photo" class="rounded-circle" style="width: 40px; height: 40px"> -->
  <avatar *ngIf="meta?.lastname; else place"
          [avatar]="meta?.avatar"
          [color]="meta?.color"
          title="{{ meta?.name }} {{ meta?.lastname }}"
          text="{{ meta?.name[0] }}{{ meta?.lastname[0] }}">
  </avatar>

  <ng-template #place>
    <mat-icon>place</mat-icon>
  </ng-template>
</a>
