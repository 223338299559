import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  CollectionReference,
  Query
} from '@angular/fire/firestore';
import { CompanyService } from 'app/db/services/company.service';
import { ApiService } from 'app/shared/services/api.service';
import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { filter, shareReplay, switchMap, tap } from 'rxjs/operators';

import { Vacation } from '../models';
import { CompaniesService } from './companies.service';

@Injectable()
export class VacationsService extends ApiService<Vacation> {
  vacations$: Observable<Vacation[]> = this.companyService.companyId$.pipe(
    filter(Boolean),
    switchMap((companyId: string) =>
      this.afs
        .collection<Vacation>(
          `companies/${companyId}/vacations`,
          (ref: CollectionReference): Query =>
            ref.orderBy(this.order.field, this.order.direction as firestore.OrderByDirection)
        )
        .valueChanges({ idField: 'id' })
    ),
    shareReplay()
  );
  model = 'Vacation';
  order = { field: 'start', direction: 'desc' };

  static search(term: string, item: Partial<Vacation>): boolean {
    return false;
  }

  constructor(
    private companiesService: CompaniesService,
    private companyService: CompanyService,
    @Inject('firebaseProject') private afs: AngularFirestore
  ) {
    super();
  }

  getFromPreviousToNextMonth(from: string, to: string): Observable<Vacation[]> {
    return this.companyService.companyId$.pipe(
      filter(Boolean),
      switchMap((companyId: string) =>
        this.afs
          .collection<Vacation>(
            `companies/${companyId}/vacations`,
            (ref: CollectionReference): Query =>
              ref
                .orderBy(this.order.field, this.order.direction as firestore.OrderByDirection)
                .where(this.order.field, '>=', subMonths(startOfMonth(new Date(from)), 1))
                .where(this.order.field, '<=', endOfMonth(addMonths(new Date(to), 1)))
          )
          .valueChanges({ idField: 'id' })
          .pipe(
            tap((vacations) => console.log('vacations', vacations)),
            shareReplay()
          )
      )
    );
  }

  getCollection({ companyId }): AngularFirestoreCollection<Vacation> {
    return this.companiesService
      .getDocument({ companyId })
      .collection<Vacation>('vacations', this.orderQuery);
  }

  getDocument({ companyId, vacationId }): AngularFirestoreDocument<Vacation> {
    return this.getCollection({ companyId }).doc<Vacation>(vacationId);
  }
}
