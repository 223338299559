import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { BaseEffects } from 'app/store/base.effects';

import { User } from './user.model';
import { UserService } from './user.service';

@Injectable()
export class UserEffects extends BaseEffects<User> {
  // @Effect()
  // query$ = this.query();
  // @Effect()
  // update$ = this.update();
  // @Effect()
  // delete$ = this.delete();
  // @Effect()
  // subscribe$ = this.subscribe();

  constructor(protected actions$: Actions, protected userService: UserService) {
    super(actions$, userService);
  }
}
