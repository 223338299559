import { Injectable } from '@angular/core';
import { DocumentReference, DocumentSnapshot } from '@angular/fire/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionType } from '@ngrx/store';
import { LoaderService } from 'app/shared/services/loader.service';
import { from } from 'rxjs';
import { map, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { EmployeesActions } from '../actions';
import { Employee } from '../models';
import { CompanyService, EmployeesService, LogsService } from '../services';

@Injectable()
export class EmployeesEffects {
  // subscribe$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(EmployeesActions.subscribe),
  //     switchMap(() => this.companiesFacade.selectedId$),
  //     switchMap((companyId: string) =>
  //       this.employeesService.get({ companyId }).pipe(
  //         takeUntil(this.actions$.pipe(ofType(EmployeesActions.unsubscribe))),
  //         map((employees: Employee[]) => EmployeesActions.set({ employees }))
  //       )
  //     )
  //   )
  // );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeesActions.add),
      pluck('employee'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([employeeData, companyId]: [Partial<Employee>, string]) =>
        this.employeesService.add({
          companyId,
          ...employeeData,
          slug: EmployeesService.createId(employeeData)
        })
      ),
      switchMap((employeeRef: DocumentReference) => from(employeeRef.get())),
      map((employee: DocumentSnapshot<Employee>) =>
        EmployeesActions.addSuccess({
          employee: {
            id: employee?.id,
            ...employee?.data()
          }
        })
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeesActions.update),
      pluck('employee'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([employeeData, companyId]: [Employee, string]) =>
        this.employeesService
          .update({
            ...employeeData,
            companyId,
            employeeId: employeeData?.id
          })
          .pipe(map((employee: Employee) => EmployeesActions.updateSuccess({ employee })))
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeesActions.remove),
      pluck('employee'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([employeeData, companyId]: [Employee, string]) =>
        this.employeesService
          .delete({
            ...employeeData,
            companyId,
            employeeId: employeeData?.id
          })
          .pipe(map((employee: Employee) => EmployeesActions.removeSuccess({ employee })))
      )
    )
  );

  addUpdateDeleteEmployeeLog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EmployeesActions.addSuccess,
          EmployeesActions.updateSuccess,
          EmployeesActions.removeSuccess
        ),
        switchMap(({ employee, type }: { employee: Employee; type: ActionType<Employee> }) =>
          this.logsService.addLog({
            object: employee,
            type: 'employee',
            ...this.getAction(type)
          })
        )
      ),
    { dispatch: false }
  );

  startLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmployeesActions.add, EmployeesActions.update, EmployeesActions.remove),
        tap(() => this.loaderService.isLoading$.next(true))
      ),
    { dispatch: false }
  );
  stopLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EmployeesActions.addSuccess,
          EmployeesActions.addFailure,
          EmployeesActions.updateSuccess,
          EmployeesActions.updateFailure,
          EmployeesActions.removeSuccess,
          EmployeesActions.removeFailure
        ),
        tap(() => this.loaderService.isLoading$.next(false))
      ),
    { dispatch: false }
  );

  getAction(type: string): { action: string } {
    switch (type) {
      case EmployeesActions.addSuccess.type:
        return { action: 'add' };
      case EmployeesActions.updateSuccess.type:
        return { action: 'update' };
      case EmployeesActions.removeSuccess.type:
        return { action: 'delete' };
    }
  }

  constructor(
    protected actions$: Actions,
    protected employeesService: EmployeesService,
    private companyService: CompanyService,
    private loaderService: LoaderService,
    private logsService: LogsService
  ) {
    // super(actions$, employeesService);
  }
}
