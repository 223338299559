import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { activitiesReducer, ActivitiesState } from './activities.reducer';
import { clientsReducer, ClientsState } from './clients.reducer';
import { companiesReducer, CompaniesState } from './companies.reducer';
import { employeesReducer, EmployeesState } from './employees.reducer';
import { eventsReducer, EventsState } from './events.reducer';
import { placesReducer, PlacesState } from './places.reducer';
import { usersReducer, UsersState } from './users.reducer';
import { vacationsReducer, VacationsState } from './vacations.reducer';
import { worksReducer, WorksState } from './works.reducer';

export interface DbState {
  activities: ActivitiesState;
  clients: ClientsState;
  companies: CompaniesState;
  employees: EmployeesState;
  events: EventsState;
  places: PlacesState;
  users: UsersState;
  vacations: VacationsState;
  works: WorksState;
}

interface State {
  db: DbState;
}

export const reducers: ActionReducerMap<DbState, any> = {
  activities: activitiesReducer,
  clients: clientsReducer,
  companies: companiesReducer,
  employees: employeesReducer,
  events: eventsReducer,
  places: placesReducer,
  users: usersReducer,
  vacations: vacationsReducer,
  works: worksReducer
};

export const getDbState = createFeatureSelector<State, DbState>('db');
