export const locale = {
  lang: 'pl',
  data: {
    NAV: {
      DASHBOARD: 'Pulpit',
      CALENDAR: 'Kalendarz',
      SCHEDULE: 'Grafik',
      MANAGER: 'Zarządzanie',
      CLIENTS: 'Klienci',
      EMPLOYEES: 'Pracownicy',
      PLACES: 'Miejsca',
      SETTINGS: 'Ustawienia'
    }
  }
};
