import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CompaniesActions } from '../actions';
import { Company } from '../models';
import {
  getCompaniesAll,
  getCompaniesIds,
  getCompaniesSelected,
  getCompaniesSelectedId
} from '../selectors/companies.selectors';

@Injectable({ providedIn: 'root' })
export class CompaniesFacade {
  all$: Observable<Company[]> = this.store.pipe(select(getCompaniesAll));

  ids$: Observable<number[] | string[]> = this.store.pipe(select(getCompaniesIds));

  selected$: Observable<Company> = this.store.pipe(select(getCompaniesSelected));

  selectedId$: Observable<number | string> = this.store.pipe(select(getCompaniesSelectedId));

  constructor(private store: Store<{}>) {}

  clear(): void {
    this.store.dispatch(CompaniesActions.clear());
  }

  select(company: Partial<Company>): void {
    this.store.dispatch(CompaniesActions.select({ company }));
  }

  subscribe(): void {
    this.store.dispatch(CompaniesActions.subscribe());
  }

  unsubscribe(): void {
    this.store.dispatch(CompaniesActions.unsubscribe());
  }
}
