import { Injectable } from '@angular/core';
import { DocumentReference, DocumentSnapshot } from '@angular/fire/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionType } from '@ngrx/store';
import { LoaderService } from 'app/shared/services/loader.service';
import { from } from 'rxjs';
import { map, pluck, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { VacationsActions } from '../actions';
import { CompaniesFacade } from '../facades';
import { Vacation } from '../models';
import { CompanyService, LogsService, VacationsService } from '../services';

@Injectable()
export class VacationsEffects {
  subscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VacationsActions.subscribe),
      switchMap(() => this.companiesFacade.selectedId$),
      switchMap((companyId: string) =>
        this.vacationsService.get({ companyId }).pipe(
          takeUntil(this.actions$.pipe(ofType(VacationsActions.unsubscribe))),
          map((vacations: Vacation[]) => VacationsActions.set({ vacations }))
        )
      )
    )
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VacationsActions.add),
      pluck('vacation'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([vacationData, companyId]: [Partial<Vacation>, string]) =>
        this.vacationsService.add({ companyId, ...vacationData })
      ),
      switchMap((vacationRef: DocumentReference) => from(vacationRef.get())),
      map((vacation: DocumentSnapshot<Vacation>) =>
        VacationsActions.addSuccess({
          vacation: {
            ...vacation?.data(),
            id: vacation?.id
          }
        })
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VacationsActions.update),
      pluck('vacation'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([vacationData, companyId]: [Partial<Vacation>, string]) =>
        this.vacationsService
          .update({ companyId, ...vacationData, vacationId: vacationData?.id })
          .pipe(map((vacation: DocumentReference) => VacationsActions.updateSuccess({ vacation })))
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VacationsActions.remove),
      pluck('vacation'),
      withLatestFrom(this.companyService.companyId$),
      switchMap(([vacationData, companyId]: [Partial<Vacation>, string]) =>
        this.vacationsService
          .delete({ companyId, ...vacationData, vacationId: vacationData?.id })
          .pipe(map((vacation: DocumentReference) => VacationsActions.removeSuccess({})))
      )
    )
  );

  addUpdateRemoveVacationLog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          VacationsActions.addSuccess,
          VacationsActions.updateSuccess,
          VacationsActions.removeSuccess
        ),
        switchMap(({ vacation, type }: { vacation: Vacation; type: ActionType<Vacation> }) =>
          this.logsService.addLog({
            object: vacation,
            type: 'vacation',
            ...this.getAction(type)
          })
        )
      ),
    { dispatch: false }
  );

  startLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VacationsActions.add, VacationsActions.update, VacationsActions.remove),
        tap(() => this.loaderService.isLoading$.next(true))
      ),
    { dispatch: false }
  );

  stopLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          VacationsActions.addSuccess,
          VacationsActions.addFailure,
          VacationsActions.updateSuccess,
          VacationsActions.updateFailure,
          VacationsActions.removeSuccess,
          VacationsActions.removeFailure
        ),
        tap(() => this.loaderService.isLoading$.next(false))
      ),
    { dispatch: false }
  );

  getAction(type: string): { action: string } {
    switch (type) {
      case VacationsActions.addSuccess.type:
        return { action: 'add' };
      case VacationsActions.updateSuccess.type:
        return { action: 'update' };
      case VacationsActions.removeSuccess.type:
        return { action: 'delete' };
    }
  }

  constructor(
    protected actions$: Actions,
    protected vacationsService: VacationsService,
    private companiesFacade: CompaniesFacade,
    private companyService: CompanyService,
    private loaderService: LoaderService,
    private logsService: LogsService
  ) {
    // super(actions$, vacationsService);
  }
}
