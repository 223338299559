import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { logout } from '../auth.actions';

@Component({
  selector: 'ptm-auth-logout-page',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutPageComponent {
  constructor(private store: Store<{}>) {
    this.store.dispatch(logout());
  }
}
