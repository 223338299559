import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { VacationsActions } from '../actions';
import { Vacation } from '../models';
import {
  getVacationsAll,
  getVacationsIds,
  getVacationsSelected,
  getVacationsSelectedId
} from '../selectors/vacations.selectors';

@Injectable({ providedIn: 'root' })
export class VacationsFacade {
  all$: Observable<Vacation[]> = this.store.pipe(select(getVacationsAll));

  ids$: Observable<number[] | string[]> = this.store.pipe(select(getVacationsIds));

  selected$: Observable<Vacation> = this.store.pipe(select(getVacationsSelected));

  selectedId$: Observable<number | string> = this.store.pipe(select(getVacationsSelectedId));

  constructor(private store: Store<{}>) {}

  clear(): void {
    this.store.dispatch(VacationsActions.clear());
  }

  select(vacation: Partial<Vacation>): void {
    this.store.dispatch(VacationsActions.select({ vacation }));
  }

  subscribe(): void {
    this.store.dispatch(VacationsActions.subscribe());
  }

  unsubscribe(): void {
    this.store.dispatch(VacationsActions.unsubscribe());
  }

  add(vacation: Partial<Vacation>): void {
    this.store.dispatch(VacationsActions.add({ vacation }));
  }

  update(vacation: Partial<Vacation>): void {
    this.store.dispatch(VacationsActions.update({ vacation }));
  }

  remove(vacation: Partial<Vacation>): void {
    this.store.dispatch(VacationsActions.remove({ vacation }));
  }
}
