import { Component } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import * as appPackage from '../../../../../package.json';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  colorTheme$: Observable<{}> = this._fuseConfigService.getConfig().pipe(pluck('colorTheme'));
  version: string = appPackage?.version;
  themeLight = 'theme-default';
  themeDark = 'theme-pink-dark';

  constructor(private _fuseConfigService: FuseConfigService) {}

  changeTheme(colorTheme: string): void {
    this._fuseConfigService.setConfig({ colorTheme });
  }
}
