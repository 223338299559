<img class="avatar"
     *ngIf="avatar; else icon"
     [alt]="title"
     [src]="avatar" />

<ng-template #icon>
  <div class="avatar accent"
       [attr.style]="background">
    {{ text }}
  </div>
</ng-template>
