import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentReference
} from '@angular/fire/firestore';
import { LogData } from 'app/db/models';
import { Log } from 'app/db/models/log.model';
import { UserService } from 'app/db/services/user.service';
import { UsersService } from 'app/db/services/users.service';
import { getAuthor } from 'app/shared/utils';
import { UserInfo } from 'firebase';
import { firestore } from 'firebase/app';
import { combineLatest, Observable, zip } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Injectable()
export class LogsService {
  order: { field: string; direction: firestore.OrderByDirection } = {
    field: 'createdAt',
    direction: 'desc'
  };
  increment = firestore.FieldValue.increment(1);

  user$: Observable<UserInfo> = this.userService.user$;

  logs$: Observable<Log[]> = this.user$.pipe(
    switchMap((user: UserInfo) => this.getCollection(user?.uid).valueChanges({ idField: 'id' }))
  );

  logsStatus$: Observable<any> = this.user$.pipe(
    switchMap((user: UserInfo) => this.getUserLogsDocument(user).valueChanges())
  );

  addLog(data: LogData, interested: string[] = []): Observable<DocumentReference[]> {
    return combineLatest([this.user$, this.usersService.adminsList$]).pipe(
      first(),
      switchMap(([user, admins]: [UserInfo, string[]]) =>
        this.postLogs(
          { data, author: getAuthor(user), createdAt: firestore.Timestamp.fromDate(new Date()) },
          this.getReceiversList(user, admins, interested)
        )
      )
    );
  }

  private getReceiversList(user: UserInfo, admins: string[], interested: string[]): string[] {
    return [...new Set([...admins, ...interested].filter((userId) => userId !== user?.uid))];
  }

  private postLogs(log: Log, receivers: string[]): Observable<DocumentReference[]> {
    return zip(receivers.map((receiver) => this.postLog(log, receiver)));
  }

  private postLog(log: Log, uid: string): Promise<DocumentReference> {
    this.afs.doc(`logs/${uid}`).set({ unread: this.increment }, { merge: true });
    return this.getCollection(uid).add(log);
  }

  private getCollection(uid: string): AngularFirestoreCollection<any> {
    return this.afs.collection<Log[]>(`logs/${uid}/logs`, (ref) =>
      ref.orderBy(this.order.field, this.order.direction)
    );
  }

  setStatus({ status }): Observable<void> {
    return this.user$.pipe(
      first(),
      switchMap((user: UserInfo) =>
        this.getUserLogsDocument(user).set({ ...status }, { merge: true })
      )
    );
  }

  getUserLogsDocument(user: UserInfo): AngularFirestoreDocument {
    return this.afs.doc<Log>(`logs/${user?.uid}`);
  }

  constructor(
    private userService: UserService,
    private usersService: UsersService,
    @Inject('firebaseProject') private afs: AngularFirestore
  ) {}
}
