import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { ApiService } from 'app/shared/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models';

@Injectable()
export class UsersService extends ApiService<User> {
  model = 'User';
  order = { field: 'uid', direction: 'asc' };

  adminsList$: Observable<string[]> = this.afs
    .collection<User>('users', (ref) => ref.where('isAdmin', '==', true))
    .valueChanges()
    .pipe(map((users: User[]) => users.map((user: User) => user?.uid)));

  static search(term: string, item: Partial<User>): boolean {
    return false;
    // return super.search(term, new User(item));
  }

  constructor(@Inject('firebaseProject') private afs: AngularFirestore) {
    super();
  }

  getCollection(): AngularFirestoreCollection<User> {
    return this.afs.collection<User>('users', this.orderQuery);
  }

  getDocument({ userId }): AngularFirestoreDocument<User> {
    return this.getCollection().doc<User>(userId);
  }
}
