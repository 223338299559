import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, Observable, Subject } from 'rxjs';
import { filter, switchMapTo, take } from 'rxjs/operators';

import { EditModeDirective } from './edit-mode.directive';
import { ViewModeDirective } from './view-mode.directive';

@UntilDestroy()
@Component({
  selector: 'editable',
  template: `
    <ng-container *ngTemplateOutlet="currentView"></ng-container>
    <span #editWrapper>
      <ng-content select="[editButton]"></ng-content>
    </span>
  `
})
export class EditableComponent implements AfterViewInit {
  @Output() update = new EventEmitter();
  @ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective) editModeTpl: EditModeDirective;
  @ViewChild('editWrapper') editButton: ElementRef;

  mode: 'view' | 'edit' = 'view';

  editMode = new Subject();
  editMode$ = this.editMode.asObservable();

  constructor(private host: ElementRef) {}

  get currentView(): TemplateRef<any> {
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }

  private viewModeHandler(): void {
    fromEvent(this.editButton.nativeElement, 'click')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.mode = 'edit';
        this.editMode.next(true);
      });
  }

  private editModeHandler(): void {
    const clickOutside$: Observable<Event> = fromEvent(document, 'click').pipe(
      filter(({ target }: Event) => this.element.contains(target) === false),
      take(1)
    );

    this.editMode$.pipe(switchMapTo(clickOutside$), untilDestroyed(this)).subscribe(() => {
      this.update.next();
      this.mode = 'view';
    });
  }

  ngAfterViewInit(): void {
    this.viewModeHandler();
    this.editModeHandler();
  }

  private get element(): any {
    return this.host.nativeElement;
  }
}
