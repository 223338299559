<div class="logs-title">
  <div fxLayout="row"
       fxLayoutAlign="space-between center">
    <div fxFlex>{{ 'LOGS.NOTIFICATIONS' | translate }}</div>
    <div fxflex>
      <button mat-icon-button
              color="accent"
              aria-label="read"
              matTooltip="{{'LOGS.MAKE_ALL_READ' | translate}}"
              type="button"
              (click)="setAllLogsRead()">
        <mat-icon>done</mat-icon>
      </button>
    </div>
  </div>
</div>
<mat-list *ngFor="let log of logs; trackBy: trackBy('id')"
          [ngClass]="{ 'log-unread': !logsStatus?.readDate || log?.createdAt > logsStatus?.readDate }">

  <mat-list-item class="log-item"
                 role="listitem">
    <img matListAvatar
         [src]="log?.author?.photoURL"
         alt="avatar">

    <ng-container [ngSwitch]="log?.data?.type"
                  matLine>
      <span *ngSwitchCase="'client'">
        <span>{{ log?.author?.displayName }} </span>
        <span [ngSwitch]="log?.data?.action">
          <span *ngSwitchCase="Actions.add">{{'LOGS.ADD' | translate}}
            {{'LOGS.CLIENT' | translate}}</span>
          <span
                *ngSwitchCase="Actions.update">{{'LOGS.UPDATE_DETAILS' | translate}}
            {{'LOGS.CLIENT' | translate}}</span>
          <span *ngSwitchCase="Actions.delete">{{'LOGS.REMOVE' | translate}}
            {{'LOGS.CLIENT' | translate}}</span>
        </span>
        <a (click)="closeLogsComponent.emit()" [routerLink]="['/manager', 'clients', log?.data?.object?.id]" ><strong> {{ log?.data?.object?.name }}
          {{ log?.data?.object?.lastname }}.</strong></a>
      </span>
      <div *ngSwitchCase="'place'">
        <span>{{ log?.author?.displayName }} </span>
        <span [ngSwitch]="log?.data?.action">
          <span *ngSwitchCase="Actions.add">{{'LOGS.ADD_PLACE' | translate}}
            {{'LOGS.PLACE' | translate}}</span>
          <span
                *ngSwitchCase="Actions.update">{{'LOGS.UPDATE_DETAILS' | translate}}
            {{'LOGS.PLACE' | translate}}</span>
          <span *ngSwitchCase="Actions.delete">{{'LOGS.REMOVE' | translate}}
            {{'LOGS.PLACE' | translate}}</span>
        </span>
        <a (click)="closeLogsComponent.emit()" [routerLink]="['/manager', 'places', log?.data?.object?.id]"> <strong> {{ log?.data?.object?.name }}.</strong></a>
      </div>
      <div *ngSwitchCase="'employee'">
        <span>{{ log?.author?.displayName }} </span>
        <span [ngSwitch]="log?.data?.action">
          <span *ngSwitchCase="Actions.add">{{'LOGS.ADD' | translate}}
            {{'LOGS.EMPLOYEE' | translate}}</span>
          <span
                *ngSwitchCase="Actions.update">{{'LOGS.UPDATE_DETAILS' | translate}}
            {{'LOGS.EMPLOYEE' | translate}}</span>
          <span *ngSwitchCase="Actions.delete">{{'LOGS.REMOVE' | translate}}
            {{'LOGS.EMPLOYEE' | translate}}</span>
        </span>
        <a (click)="closeLogsComponent.emit()" [routerLink]="['/manager', 'employees', log?.data?.object?.slug]"> <strong> {{ log?.data?.object?.name }}
          {{ log?.data?.object?.lastname }}.</strong></a>
      </div>
      <div *ngSwitchCase="'event'">
        <span>{{ log?.author?.displayName }} </span>
        <span [ngSwitch]="log?.data?.action">
          <span *ngSwitchCase="Actions.add">{{'LOGS.ADD_EVENT' | translate}}
            {{'LOGS.EVENT' | translate}}</span>
          <span
                *ngSwitchCase="Actions.update">{{'LOGS.UPDATE_DETAILS' | translate}}
            {{'LOGS.EVENT' | translate}}</span>
          <span *ngSwitchCase="Actions.delete">{{'LOGS.REMOVE' | translate}}
            {{'LOGS.EVENT' | translate}}</span>
        </span>
        &bull;
        <a (click)="closeLogsComponent.emit()" [routerLink]="['/manager', 'clients', log?.data?.object?.client?.id]"> <strong> {{ log?.data?.object?.client?.name }}
          {{ log?.data?.object?.client?.lastname }}.</strong></a>
        &bull;
        <span
              [matTooltip]="log?.data?.object?.start.toDate() | localeDate:'full'">{{ log?.data?.object?.start.toDate() | localeDate:'short' }}</span>
        &bull;
        <a (click)="
              closeLogsComponent.emit()"
              [routerLink]="['/manager', 'places', log?.data?.object?.place?.id]">
          <strong>{{ log?.data?.object?.place?.name }}</strong></a>
        &bull;
        <a (click)="closeLogsComponent.emit()" [routerLink]="['/manager', 'employees', log?.data?.object?.employee?.id]"> <strong> {{ log?.data?.object?.employee?.name }}
          {{ log?.data?.object?.employee?.lastname }}.</strong></a>
      </div>
      <div class="log-time">
        <time [matTooltip]="log?.createdAt.toDate() | localeDate:'full'"
              [attr.datetime]="log?.createdAt.toDate().toISOString()">{{ log?.createdAt.toDate() | distanceInWordsTranslate }}
          {{'LOGS.AGO' | translate }}
        </time>
      </div>
    </ng-container>

  </mat-list-item>

  <mat-divider></mat-divider>
</mat-list>
