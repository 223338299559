import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';

import { CompanySwitcherComponent } from './company-switcher.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  declarations: [CompanySwitcherComponent],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,

    FuseSharedModule,
    SharedModule
  ],
  exports: [CompanySwitcherComponent]
})
export class CompanySwitcherModule {}
