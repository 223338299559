import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { PlacesActions } from '../actions';
import { CompaniesFacade } from '../facades';
import { Place } from '../models';
import { PlacesService } from '../services';

@Injectable()
export class PlacesEffects {
  subscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlacesActions.subscribe),
      switchMap(() => this.companiesFacade.selectedId$),
      switchMap((companyId: string) =>
        this.placesService.get({ companyId }).pipe(
          takeUntil(this.actions$.pipe(ofType(PlacesActions.unsubscribe))),
          map((places: Place[]) => PlacesActions.set({ places }))
        )
      )
    )
  );

  constructor(
    protected actions$: Actions,
    protected placesService: PlacesService,
    private companiesFacade: CompaniesFacade
  ) {
    // super(actions$, placesService);
  }
}
