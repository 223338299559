import { createAction, props, union } from '@ngrx/store';

import { Activity } from '../models';

export const clear = createAction('[DB/Activities] clear');

export const select = createAction(
  '[DB/Activities] select',
  props<{ activity: Partial<Activity> }>()
);

export const set = createAction('[DB/Activities] set', props<{ activities: Activity[] }>());
export const subscribe = createAction('[DB/Activities] subscribe');
export const unsubscribe = createAction('[DB/Activities] unsubscribe');

const all = union({
  select,
  set,
  subscribe,
  unsubscribe
});
export type ActivitiesActionsUnion = typeof all;
