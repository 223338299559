import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { timestampReviver } from 'app/shared/utils';
import { CustomSerializer, effects, reducers, State } from 'app/store';
import { environment } from 'environments/environment';
import { storeFreeze } from 'ngrx-store-freeze';
import { localStorageSync } from 'ngrx-store-localstorage';

export function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
  return localStorageSync({
    rehydrate: true,
    restoreDates: false,
    keys: [
      'layout',
      'router',
      'settings',
      // 'users',
      // { db: timestampReviver },
      // { notifications: timestampReviver },
      { auth: { filter: ['status'], reviver: timestampReviver } }
    ]
  })(reducer);
}
export const metaReducers: MetaReducer<State>[] = [
  localStorageSyncReducer,
  ...(!environment.production ? [storeFreeze] : [])
];

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          name: 'ptm', // environment.config.name,
          logOnly: environment.production
        })
      : [],
    StoreRouterConnectingModule.forRoot()
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomSerializer
    }
  ]
})
export class AppStoreModule {}
