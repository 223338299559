import { Component, ViewEncapsulation } from '@angular/core';
import { LoaderService } from 'app/shared';

@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentComponent {
  constructor(public loaderService: LoaderService) {}
}
