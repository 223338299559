<div id="login"
     fxLayout="column">
  <div id="login-form-wrapper"
       fxLayout="column"
       fxLayoutAlign="center center">

    <div id="login-form"
         [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
      <div class="logo">
        <img src="assets/images/logos/fuse.svg">
      </div>
      <!--
      <div class="title">LOGIN TO YOUR ACCOUNT</div>

      <form name="loginForm"
            [formGroup]="loginForm"
            (ngSubmit)="login(loginForm)"
            novalidate>

        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput
                 formControlName="email">
          <mat-icon matSuffix
                    class="secondary-text">mail</mat-icon>
          <mat-error *ngIf="loginForm.get('email').hasError('required')">
            Email is required
          </mat-error>
          <mat-error *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput
                 type="password"
                 formControlName="password">
          <mat-icon matSuffix
                    class="secondary-text">vpn_key</mat-icon>
          <mat-error>
            Password is required
          </mat-error>
        </mat-form-field>

        <div class="remember-forgot-password"
             fxLayout="row"
             fxLayout.xs="column"
             fxLayoutAlign="space-between center">
          <mat-checkbox class="remember-me"
                        aria-label="Remember Me">
            Remember Me
          </mat-checkbox>

          <a class="forgot-password" [routerLink]="'/pages/auth/forgot-password'">
            Forgot Password?</a>
        </div>

        <button mat-raised-button
                color="accent"
                class="submit-button"
                aria-label="LOG IN"
                [disabled]="loginForm?.invalid || (pending$ | async)">
          LOGIN
        </button>

        <mat-error *ngIf="(error$ | async) as error"
                   class="alert alert-danger">
          {{ error }}
        </mat-error>
      </form>

      <div class="separator">
        <span class="text">OR</span>
      </div>

      <button mat-raised-button
              class="google"
              (click)="loginWithGoogle()">
        Log in with Google
      </button>

      <button mat-raised-button
              class="facebook"
              (click)="loginWithFacebook()">
        Log in with Facebook
      </button>

      <div class="register"
           fxLayout="column"
           fxLayoutAlign="center center">
        <span class="text">Don't have an account?</span>
        <a class="link" [routerLink]="'/pages/auth/register'">Create an account</a>
      </div> -->

      <ngx-auth-firebaseui [providers]="['google', 'facebook']"
                           (onSuccess)="success($event)">
      </ngx-auth-firebaseui>

    </div>
  </div>
</div>
