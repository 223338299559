import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

import { UsersService } from '../services';

@Injectable()
export class UsersEffects {
  // subscribe$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UsersActions.subscribe),
  //     switchMap(() =>
  //       this.usersService.get().pipe(
  //         takeUntil(this.actions$.pipe(ofType(UsersActions.unsubscribe))),
  //         map((users: User[]) => UsersActions.set({ users }))
  //       )
  //     )
  //   )
  // );

  constructor(protected actions$: Actions, protected usersService: UsersService) {
    // super(actions$, userService);
  }
}
