import { createAction, props, union } from '@ngrx/store';

import { Event } from '../models';

export const clear = createAction('[DB/Events] clear');

export const select = createAction('[DB/Events] select', props<{ event: Partial<Event> }>());

export const set = createAction('[DB/Events] set', props<{ events: Event[] }>());
export const subscribe = createAction('[DB/Events] subscribe');
export const unsubscribe = createAction('[DB/Events] unsubscribe');

export const add = createAction('[DB/Events] add', props<{ event: Partial<Event> }>());
export const addSuccess = createAction(
  '[DB/Events] add success',
  props<{ event?: Partial<Event> }>()
);
export const addFailure = createAction('[DB/Events] add failure');

export const update = createAction('[DB/Events] update', props<{ event: Partial<Event> }>());
export const updateSuccess = createAction(
  '[DB/Events] update success',
  props<{ event?: Partial<Event> }>()
);
export const updateFailure = createAction('[DB/Events] update failure');

export const remove = createAction('[DB/Events] remove', props<{ event: Partial<Event> }>());
export const removeSuccess = createAction(
  '[DB/Events] remove success',
  props<{ event?: Partial<Event> }>()
);
export const removeFailure = createAction('[DB/Events] remove failure');

const all = union({
  select,
  set,
  subscribe,
  unsubscribe,
  add,
  addSuccess,
  addFailure,
  update,
  updateSuccess,
  updateFailure,
  remove,
  removeSuccess,
  removeFailure
});
export type EventsActionsUnion = typeof all;
