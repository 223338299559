import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UserAccess } from 'app/db';
import { Map } from 'app/shared/models';
import { Observable } from 'rxjs';

import { getUser, getUserAccess } from './auth.reducer';
import { User } from './user';

@Injectable()
export class AuthFacade {
  user$: Observable<User> = this.store.pipe(select(getUser));
  userAccess$: Observable<Map<UserAccess>> = this.store.pipe(select(getUserAccess));
  constructor(private store: Store<{}>) {}
}
