export const locale = {
  lang: 'en',
  data: {
    LOGS: {
      NOTIFICATIONS: 'Notifications',
      MAKE_ALL_READ: 'Mark all as read',
      ADD: 'added new',
      ADD_PLACE: 'added new',
      ADD_EVENT: 'added new',
      UPDATE_DETAILS: 'updated details of',
      REMOVE: 'removed',
      CLIENT: 'client',
      PLACE: 'place',
      EMPLOYEE: 'employee',
      EVENT: 'training',
      AGO: 'ago'
    }
  }
};
