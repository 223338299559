import { Injectable } from '@angular/core';
import { UserInfo } from 'firebase';
import { AuthProcessService, FirestoreSyncService } from 'ngx-auth-firebaseui';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  first,
  pluck,
  shareReplay,
  switchMap,
  tap
} from 'rxjs/operators';

import { UserAccess } from '../models';

@Injectable()
export class UserService {
  user$: Observable<UserInfo> = this.authProcess.user$.pipe(
    pluck('uid'),
    distinctUntilChanged(),
    tap((uid: string) => {
      if (this.isAuthorized && !uid) {
        window.location.reload();
      }
    }),
    switchMap((uid: string) => this.firestoreSync.getUserDocRefByUID(uid).valueChanges()),
    shareReplay()
  );
  access$: Observable<UserAccess> = this.user$.pipe(pluck('access'));
  isAdmin$: Observable<boolean> = this.user$.pipe(pluck('isAdmin'));

  isAdmin = false;
  isAuthorized = false;

  constructor(
    private authProcess: AuthProcessService,
    private firestoreSync: FirestoreSyncService
  ) {}

  load(): Promise<boolean> {
    return new Promise((resolve) => {
      this.user$
        .pipe(
          finalize(() => resolve(true)),
          first(),
          filter(Boolean)
        )
        .subscribe((user: any) => {
          if (!!user) {
            this.isAdmin = user.isAdmin;
            this.isAuthorized = true;
          }
        });
    });
  }
}
