import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { addHours, addMinutes } from 'date-fns';

import { Vacation } from '../models';

@Injectable()
export class VacationsFormService {
  constructor(private formBuilder: FormBuilder) {}

  vacation(vacation?: Partial<Vacation>): FormGroup {
    return this.formBuilder.group({
      start: '',
      end: '',
      desc: '',
      employeeId: '',
      startTime: '',
      endTime: '',
      ...vacation
    });
  }

  getFullDatetime(date: Date, time: string): Date {
    const [hours, minutes] = time?.split(':');
    return addHours(addMinutes(date, parseInt(minutes, 10)), parseInt(hours, 10));
  }
}
