import { Injectable } from '@angular/core';
import { DocumentChangeAction } from '@angular/fire/firestore';
import { Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { FirebaseError } from 'firebase/app';
import { Observable, of } from 'rxjs';
import { map, mergeMap, pluck, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import { ApiService } from 'app/shared/services/api.service';

import { actionTypeFailure, actionTypeSuccess } from './base.actions';

@Injectable()
export class BaseEffects<T> {
  protected isLoaded$: Observable<boolean> = of(false);

  protected setType = `[${this.service.model}] set`;
  protected subType = `[${this.service.model}] subscribe`;
  protected unsubType = `[${this.service.model}] unsubscribe`;

  protected queryType = `[${this.service.model}] query`;
  protected createType = `[${this.service.model}] create`;
  protected updateType = `[${this.service.model}] update`;
  protected deleteType = `[${this.service.model}] delete`;
  protected selectType = `[${this.service.model}] select`;

  constructor(protected actions$: Actions, protected service: ApiService<T>) {}

  protected query = (failureFn?: (err?: FirebaseError) => Action): Observable<Action> =>
    this.customQuery((change, payload) => this.changeActionWithPayload(change, payload), failureFn);

  protected customQuery = (
    changesFn: (action: DocumentChangeAction<T>, payload?) => Action,
    failureFn?: (err?: FirebaseError) => Action
  ): Observable<Action> =>
    this.actions$.pipe(
      ofType(this.queryType),
      pluck('payload'),
      switchMap((payload: Partial<T>) =>
        this.service.collectionChanges(payload).pipe(
          withLatestFrom(this.isLoaded$),
          mergeMap(([changes, isLoaded]) => [
            ...(!(isLoaded && changes.isInit)
              ? changes.actions.map((change: DocumentChangeAction<T>) => changesFn(change, payload))
              : []),
            { type: `[${this.service.model}] loaded` }
          ])
          // catchFailure((err) => failureFn(err))
        )
      )
    );

  protected subscribe = (
    successFn: (payload?) => Action = (payload?) => ({
      type: `${this.setType}`,
      payload
    }),
    failureFn: (err?: FirebaseError) => Action = (payload?) => ({
      type: `${this.setType} ${actionTypeFailure}`,
      payload
    })
  ): Observable<{}> =>
    this.actions$.pipe(
      ofType(this.subType),
      pluck('payload'),
      switchMap((payload: {}) =>
        this.service.get(payload).pipe(
          takeUntil(this.actions$.pipe(ofType(this.unsubType))),
          map((result) => successFn(result))
          // catchFailure((err) => failureFn(err))
        )
      )
    );

  protected add = (
    successFn: (payload?) => Action = (payload?) => ({
      type: `${this.createType} ${actionTypeSuccess}`,
      payload
    }),
    failureFn: (err?: FirebaseError) => Action = (payload?) => ({
      type: `${this.createType} ${actionTypeFailure}`,
      payload
    })
  ): Observable<{}> =>
    this.actions$.pipe(
      ofType(this.createType),
      pluck('payload'),
      switchMap((payload: {}) =>
        this.service.add(payload).pipe(
          map((result) => successFn(result))
          // catchFailure((err) => failureFn(err))
        )
      )
    );

  protected set = (
    successFn: (payload?) => Action = (payload?) => ({
      type: `${this.createType} ${actionTypeSuccess}`,
      payload
    }),
    failureFn: (err?: FirebaseError) => Action = (payload?) => ({
      type: `${this.createType} ${actionTypeFailure}`,
      payload
    })
  ): Observable<{}> =>
    this.actions$.pipe(
      ofType(this.createType),
      pluck('payload'),
      switchMap((payload: {}) =>
        this.service.set(payload).pipe(
          map((result) => successFn(result))
          // catchFailure((err) => failureFn(err))
        )
      )
    );

  protected update = (
    successFn: (payload?) => Action = (payload?) => ({
      type: `${this.updateType} ${actionTypeSuccess}`,
      payload
    }),
    failureFn: (err?: FirebaseError) => Action = (payload?) => ({
      type: `${this.updateType} ${actionTypeFailure}`,
      payload
    })
  ): Observable<{}> =>
    this.actions$.pipe(
      ofType(this.updateType),
      pluck('payload'),
      switchMap((payload: {}) =>
        this.service.update(payload).pipe(
          map((result) => successFn(result))
          // catchFailure((err) => failureFn(err))
        )
      )
    );

  protected delete = (
    successFn: (payload?) => Action = (payload?) => ({
      type: `${this.deleteType} ${actionTypeSuccess}`,
      payload
    }),
    failureFn: (err?: FirebaseError) => Action = (payload?) => ({
      type: `${this.deleteType} ${actionTypeFailure}`,
      payload
    })
  ): Observable<{}> =>
    this.actions$.pipe(
      ofType(this.deleteType),
      pluck('payload'),
      switchMap((payload: {}) =>
        this.service.delete(payload).pipe(
          map((result) => successFn(result))
          // catchFailure((err) => failureFn(err))
        )
      )
    );

  private changeActionWithPayload({ type, payload: { doc } }: DocumentChangeAction<T>, path: {}) {
    const id = !!doc && doc.id;
    const data: {} = !!doc && doc.data();
    const model = this.service.model.toLowerCase();
    return {
      type: `[${this.service.model}] ${type}`,
      payload: {
        ...path,
        [`${model}Update`]: {
          id,
          changes: { ...path, ...data, id, [`${model}Id`]: id }
        }
      }
    };
  }
}
