import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EmployeesService } from 'app/db/services/employees.service';
import { Observable } from 'rxjs';

import { EmployeesActions } from '../actions';
import { Employee } from '../models';
import {
  getEmployeesAll,
  getEmployeesForCalendar,
  getEmployeesIds,
  getEmployeesSelected,
  getEmployeesSelectedId
} from '../selectors/employees.selectors';

@Injectable({ providedIn: 'root' })
export class EmployeesFacade {
  all$: Observable<Employee[]> = this.employeesService.employees$;

  fromStore$: Observable<Employee[]> = this.store.pipe(select(getEmployeesAll));

  forCalendar$: Observable<any[]> = this.store.pipe(select(getEmployeesForCalendar));

  ids$: Observable<number[] | string[]> = this.store.pipe(select(getEmployeesIds));

  selected$: Observable<Employee> = this.store.pipe(select(getEmployeesSelected));

  selectedId$: Observable<string> = this.store.pipe(select(getEmployeesSelectedId));

  constructor(private store: Store<{}>, private employeesService: EmployeesService) {}

  clear(): void {
    this.store.dispatch(EmployeesActions.clear());
  }

  select(employee: Partial<Employee>): void {
    this.store.dispatch(EmployeesActions.select({ employee }));
  }

  subscribe(): void {
    this.store.dispatch(EmployeesActions.subscribe());
  }

  unsubscribe(): void {
    this.store.dispatch(EmployeesActions.unsubscribe());
  }

  add(employee: Partial<Employee>): void {
    this.store.dispatch(EmployeesActions.add({ employee }));
  }

  update(employee: Partial<Employee>): void {
    this.store.dispatch(EmployeesActions.update({ employee }));
  }

  remove(employee: Partial<Employee>): void {
    this.store.dispatch(EmployeesActions.remove({ employee }));
  }
}
