import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentReference
} from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ClientsFacade } from 'app/db/facades';
import { Bill, Client, Company } from 'app/db/models';
import { CompanyService } from 'app/db/services/company.service';
import { UserService } from 'app/db/services/user.service';
import { UserInfo } from 'firebase';
import { combineLatest, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { Transaction } from '../models';

@Injectable()
export class BillsService {
  constructor(
    @Inject('firebaseProject') private afs: AngularFirestore,
    private userService: UserService,
    private clientsFacade: ClientsFacade,
    private companyService: CompanyService,
    @Inject('firebaseFunctions') private cloudFunctions: AngularFireFunctions
  ) {}

  clientId$: Observable<string> = this.clientsFacade.selectedId$;
  client$: Observable<Client> = this.clientsFacade.selected$;
  user$: Observable<UserInfo> = this.userService.user$;
  company$: Observable<Company> = this.companyService.company$;

  bills$: Observable<Bill[]> = this.clientId$.pipe(
    first(),
    switchMap((clientId: string) =>
      this.getCollection('bills', clientId).valueChanges({ idField: 'id' })
    )
  );

  generateBill(discount: number, startDate: Date, endDate: Date): Observable<Bill> {
    const start: number = startDate.getTime();
    const end: number = endDate.getTime();
    const genBill = this.cloudFunctions.httpsCallable('generateBill');
    return genBill({
      company: 'egobody',
      user: { id: 'Ga9AZQCqpbXhzTQHNiTdqxrJOhx1', displayName: 'Bartlomiej Sobczak' },
      discount,
      start,
      end
    });
  }
  selectProps = ({ id, name, lastname }: Partial<Client>) => ({
    id,
    name,
    lastname
  });

  addBill(
    transactions: Partial<Transaction[]>,
    discount: number,
    sumTransactions: number
  ): Observable<DocumentReference> {
    return combineLatest([this.user$, this.client$, this.company$]).pipe(
      first(),
      switchMap(([user, client, company]) => {
        const author = this.createAuthor(user);
        const clientBasicInfo = this.selectProps(client);
        return this.getCollection('bills', client.id).add({
          client: clientBasicInfo,
          transactions,
          createdAt: new Date(Date.now()),
          discount,
          companyDetails: company.profile,
          sent: false,
          paid: false,
          total: sumTransactions - discount,
          paymentID: ''
        });
      })
    );
  }

  updateBill(property: Partial<Bill>, id: string): Observable<void> {
    return this.clientId$.pipe(
      first(),
      switchMap((clientId: string) => {
        return this.getCollection('bills', clientId).doc(id).update(property);
      })
    );
  }

  cancelPayment(id: string): any {
    return this.clientId$.pipe(
      first(),
      switchMap((clientId: string) => this.getCollection('transactions', clientId).doc(id).delete())
    );
  }

  private createAuthor(user: UserInfo): { id: string; displayName: string } {
    return { id: user.uid, displayName: user.displayName };
  }

  private getDocument(collection: string, uid: string): AngularFirestoreDocument<any> {
    return this.afs.doc<any>(`${collection}/${uid}`);
  }

  private getCollection(
    collection: string,
    uid: string,
    startDate?: Date,
    endDate: Date = new Date(Date.now())
  ): AngularFirestoreCollection<any> {
    return this.getDocument(collection, uid).collection<any>(collection, (ref) => {
      const query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      return (startDate
        ? query.where('createdAt', '>', startDate).where('createdAt', '<', endDate)
        : query
      ).orderBy('createdAt', 'asc');
    });
  }
}
