import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'app/shared/services/api.service';

import { User } from './user.model';

@Injectable()
export class UserService extends ApiService<User> {
  model = 'User';

  constructor(@Inject('firebaseProject') private afs: AngularFirestore) {
    super();
  }

  getCollection(): AngularFirestoreCollection<User> {
    return this.afs.collection<User>('users', this.orderQuery);
  }

  getDocument({ uid }: Partial<User>): AngularFirestoreDocument<User> {
    return this.getCollection().doc<User>(uid);
  }

  getByEmail(email: string): Observable<User> {
    return this.where(null, 'email', '==', email).pipe(
      map(
        (docs: firestore.QueryDocumentSnapshot[]) => !!docs && !!docs[0] && (docs[0].data() as User)
      )
    );
  }
}
