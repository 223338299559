import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePlExtra from '@angular/common/locales/extra/pl';
import localePl from '@angular/common/locales/pl';
import { APP_INITIALIZER, LOCALE_ID, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { PerformanceMonitoringService } from '@angular/fire/performance';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { AppComponent } from 'app/app.component';
import { UserService } from 'app/db/services/user.service';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppStoreModule } from 'app/store/store.module';
import { environment } from 'environments/environment';
import { AuthProcessService, FirestoreSyncService } from 'ngx-auth-firebaseui';
import { ToastrModule } from 'ngx-toastr';

import 'hammerjs';

import { DbModule } from './db/db.module';
import {
  AngularFireAuthProject,
  AngularFireFunctionsBills,
  AngularFireStorageBills,
  AngularFirestoreBills,
  AngularFirestoreChats,
  AngularFirestoreEvents,
  AngularFirestoreFunctions,
  AngularFirestoreLogs,
  AngularFirestoreProject
} from './firebase.factory';
import { AuthModule } from './main/auth';

registerLocaleData(localePl, 'pl', localePlExtra);

// const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);

const appRoutes: Routes = [
  // {
  //   path: 'companies',
  //   ...canActivate(redirectUnauthorizedToLogin),
  //   loadChildren: () => import('./main/apps/companies/companies.module').then(m => m.CompaniesModule)
  // },
  {
    path: '',
    // canActivate: [LoggedInGuard],
    // ...canActivate(redirectUnauthorizedToLogin),
    // loadChildren: () => import('./main/apps/apps.module').then((m) => m.AppsModule)
    loadChildren: () =>
      import('./main/auth-handler/auth-handler.module').then((m) => m.AuthHandlerModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then((m) => m.PagesModule)
  },
  {
    path: 'ui',
    loadChildren: () => import('./main/ui/ui.module').then((m) => m.UIModule)
  },
  {
    path: 'documentation',
    loadChildren: () =>
      import('./main/documentation/documentation.module').then((m) => m.DocumentationModule)
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),

    TranslateModule.forRoot(),
    InMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    ToastrModule.forRoot(),

    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFirestoreModule.enablePersistence(),
    // AngularFirePerformanceModule,

    // App modules
    LayoutModule,
    AppStoreModule,
    DbModule,
    AuthModule
  ],
  providers: [
    PerformanceMonitoringService,
    {
      provide: APP_INITIALIZER,
      useFactory: (userService: UserService) => () => userService.load(),
      deps: [UserService, AuthProcessService, FirestoreSyncService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => translate.currentLang,
      deps: [TranslateService],
      useValue: 'pl'
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (translate: TranslateService) => translate.currentLang,
      deps: [TranslateService]
    },
    {
      provide: 'env',
      useValue: environment
    },
    {
      provide: 'firebaseAuthProject',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFireAuthProject
    },
    {
      provide: 'firebaseProject',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreProject
    },
    {
      provide: 'firebaseFunctions',
      deps: [NgZone],
      useFactory: AngularFirestoreFunctions
    },
    {
      provide: 'firebaseChats',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreChats
    },
    {
      provide: 'firebaseEvents',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreEvents
    },
    {
      provide: 'firebaseLogs',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreLogs
    },
    {
      provide: 'firebaseBills',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreBills
    },
    {
      provide: 'firestorageBills',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFireStorageBills
    },
    {
      provide: 'firefunctionsBills',
      deps: [NgZone],
      useFactory: AngularFireFunctionsBills
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
