import { format } from 'date-fns';

export const config = {
  name: 'Planelo',
  colors: {
    green: '#8fdf82',
    red: '#ff9f89'
  },
  format: {
    currency: 'PLN',
    date: 'YYYY-MM-DD',
    dateTime: 'yyyy-MM-dd HH:mm',
    dayDate: 'EEEE, dd.MM.yyyy',
    dayDateTime: 'dd.MM.yyyy, HH:mm',
    time: 'HH:mm',
    month: 'yyyy-MM',
    monthDesc: 'LLLL yyyy'
  },
  company: {
    events: {
      breakpoint: {
        subHours: null, // 'X hours before event'
        // OR:
        hours: 21, // 'static' hour
        subDays: 1 // 'X days before event'
      }
    },
    dayStartHour: 1,
    dayEndHour: 23,
    weekStartsOn: 1,
    hiddenDays: [0, 6],
    locale: 'en'
  }
};

export const calendarOptions: any = {
  schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
  height: 'auto',
  contentHeight: 'auto',
  allDaySlot: false,
  // scrollTime: '07:30',
  nowIndicator: true,
  eventTextColor: 'white',

  eventOverlap: true,
  editable: true,
  eventDurationEditable: false,
  dayMaxEventRows: 1,
  selectable: true,
  // selectHelper: false,

  headerToolbar: {
    start: 'prev,today,next',
    center: 'title',
    end: 'resourceTimeGridTwoDay,resourceTimeGridWeek'
  },
  initialView: 'resourceTimeGridTwoDay',
  views: {
    // resourceTimeGrid: {
    //   // groupByResource: true,
    //   // groupByDateAndResource: true,
    //   // filterResourcesWithEvents: true
    // },
    resourceTimeGridTwoDay: {
      type: 'resourceTimeGrid',
      duration: { days: 2 },
      buttonText: '2 days'
    }
  },
  datesAboveResources: true
  // dayRender: (date, $cell: HTMLElement) => {}

  // eventClick: this.calendarEvent.bind(this),
  // select: this.calendarSelect.bind(this),
  // eventDrop: this.calendarDrag.bind(this),
  // eventRender: this.calendarTooltip.bind(this)
};
