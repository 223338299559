import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import { ApiService } from 'app/shared/services/api.service';
import { slugify } from 'app/shared/utils';

import { Activity } from '../models';
import { CompaniesService } from './companies.service';

@Injectable()
export class ActivitiesService extends ApiService<Activity> {
  model = 'Activity';
  order = { field: 'name', direction: 'asc' };

  static createId({ name }: Activity, nextId?: number): string {
    return slugify(`${name}${nextId > 1 ? nextId : ''}`);
  }

  static search(term: string, item: Partial<Activity>): boolean {
    return false;
    // return super.search(term, new Activity(item));
  }

  constructor(private companiesService: CompaniesService) {
    super();
  }

  getCollection({ companyId }): AngularFirestoreCollection<Activity> {
    return this.companiesService
      .getDocument({ companyId })
      .collection<Activity>('activities', this.orderQuery);
  }

  getDocument({ companyId, activityId }): AngularFirestoreDocument<Activity> {
    return this.getCollection({ companyId }).doc<Activity>(activityId);
  }
}
