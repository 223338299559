import { Inject, Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentReference
} from '@angular/fire/firestore';
import { MessageData } from 'app/db';
import { UserService } from 'app/db/services';
import { UserInfo } from 'firebase';
import { Howl } from 'howler';
import { Observable, zip } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Injectable()
export class ChatPanelService {
  user$: Observable<UserInfo> = this.userService.user$;
  sound = new Howl({ src: ['assets/message.mp3'] });

  constructor(
    private userService: UserService,
    @Inject('firebaseChats') private afs: AngularFirestore
  ) {}

  getMessages(receiverId: string): Observable<any> {
    return this.user$.pipe(
      switchMap((user: any) =>
        this.getCollection(user.access.egobody.employee, receiverId).valueChanges({ idField: 'id' })
      )
    );
  }

  sendMessage(
    message: string,
    receiverId: string
  ): Observable<[DocumentReference, DocumentReference]> {
    this.sound.play();
    return this.user$.pipe(
      first(),
      switchMap((user: any) => {
        const msgData = {
          message,
          receiverId,
          senderId: user.access.egobody.employee,
          createdAtDate: new Date(Date.now())
        };
        return this.postMessages(msgData);
      })
    );
  }

  private postMessages(
    messageData: Partial<MessageData>
  ): Observable<[DocumentReference, DocumentReference]> {
    const senderMsg = { ...messageData, author: true, createdAtDate: new Date(Date.now()) };
    const receiverMsg = {
      ...messageData,
      senderId: messageData.receiverId,
      receiverId: messageData.senderId,
      author: false,
      createdAt: new Date(Date.now())
    };
    return zip(this.postMessage(senderMsg), this.postMessage(receiverMsg));
  }

  private postMessage(messageData: any): Promise<DocumentReference> {
    const { senderId } = messageData;
    return this.getCollection(senderId).add({
      receiverId: messageData.receiverId,
      message: messageData.message,
      author: messageData.author,
      createdAt: messageData.createdAtDate
    });
  }

  private getCollection(uid: string, receiverId?: string): AngularFirestoreCollection<any> {
    return this.afs.doc<any>(`chats/${uid}`).collection<any>('messages', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (receiverId) {
        query = query.where('receiverId', '==', receiverId);
      }
      return query.orderBy('createdAt');
    });
  }
}
