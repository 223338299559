import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import * as ColorHash from 'color-hash';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() avatar: string;
  @Input() title: string;
  @Input() text: string;

  @Input() set color(color: string) {
    if (!color) {
      return;
    }
    this.setBgColor(color);
  }

  @Input() set id(id: string) {
    if (!id) {
      return;
    }
    this.setBgColor(new ColorHash().hex(id));
  }

  background: SafeStyle;

  constructor(private domSanitizer: DomSanitizer) {}

  setBgColor(color: string): void {
    this.background = this.domSanitizer.bypassSecurityTrustStyle(
      `background-color: ${color} !important`
    );
  }
}
