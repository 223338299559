import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoaderService } from 'app/shared/services/loader.service';
import { map, pluck, switchMap, withLatestFrom } from 'rxjs/operators';

import { ClientsActions, CompanyActions } from '../actions';
import { Client, Company } from '../models';
import { CompanyService } from '../services';

@Injectable()
export class CompanyEffects {
  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActions.addSuccess),
      pluck('client'),
      withLatestFrom(this.companyService.company$),
      switchMap(([client, company]: [Partial<Client>, Company]) => {
        const { companyId, clients } = company;
        return this.companyService.addClient({
          client,
          clients,
          companyId
        });
      }),
      map(() => CompanyActions.addClientSuccess({}))
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActions.updateSuccess),
      pluck('client'),
      withLatestFrom(this.companyService.company$),
      switchMap(([client, company]: [Partial<Client>, Company]) => {
        const { companyId, clients } = company;
        return this.companyService.updateClient({
          client,
          clients,
          companyId
        });
      }),
      map(() => CompanyActions.updateClientSuccess({}))
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActions.removeSuccess),
      pluck('client'),
      withLatestFrom(this.companyService.company$),
      switchMap(([client, company]: [Partial<Client>, Company]) => {
        const { companyId, clients } = company;
        return this.companyService.deleteClient({
          client,
          clients,
          companyId
        });
      }),
      map(() => CompanyActions.removeClientSuccess({}))
    )
  );

  constructor(
    protected actions$: Actions,
    private companyService: CompanyService,
    private loaderService: LoaderService
  ) {}
}
