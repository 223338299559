import { Action } from '@ngrx/store';

import { baseActionTypes } from 'app/store/base.actions';

import { User } from './user.model';

export const UserActionTypes = baseActionTypes('User');

export class Set implements Action {
  readonly type = UserActionTypes.Set;
  constructor(public payload: User[]) {}
}
export class Subscribe implements Action {
  readonly type = UserActionTypes.Subscribe;
  constructor(public payload?) {}
}
export class Unsubscribe implements Action {
  readonly type = UserActionTypes.Unsubscribe;
  constructor(public payload?) {}
}

export class Create implements Action {
  readonly type = UserActionTypes.Create;
  constructor(public payload: Partial<User>) {}
}
export class CreateSuccess implements Action {
  readonly type = UserActionTypes.CreateSuccess;
  constructor(public payload?) {}
}
export class CreateFailure implements Action {
  readonly type = UserActionTypes.CreateFailure;
  constructor(public payload?) {}
}

export class Update implements Action {
  readonly type = UserActionTypes.Update;
  constructor(public payload: Partial<User>) {}
}
export class UpdateSuccess implements Action {
  readonly type = UserActionTypes.UpdateSuccess;
  constructor(public payload?) {}
}
export class UpdateFailure implements Action {
  readonly type = UserActionTypes.UpdateFailure;
  constructor(public payload?) {}
}

export class Delete implements Action {
  readonly type = UserActionTypes.Delete;
  constructor(public payload: Partial<User>) {}
}
export class DeleteSuccess implements Action {
  readonly type = UserActionTypes.DeleteSuccess;
  constructor(public payload?) {}
}
export class DeleteFailure implements Action {
  readonly type = UserActionTypes.DeleteFailure;
  constructor(public payload?) {}
}

export class Select implements Action {
  readonly type = UserActionTypes.Select;
  constructor(public payload: Partial<User>) {}
}

export type UserActionsUnion =
  | Set
  | Subscribe
  | Unsubscribe
  | Create
  | CreateSuccess
  | CreateFailure
  | Update
  | UpdateSuccess
  | UpdateFailure
  | Delete
  | DeleteSuccess
  | DeleteFailure
  | Select;
