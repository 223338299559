import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewRef
} from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar } from '@fullcalendar/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { CalendarEvent } from './calendar-event.model';
import { CalendarResource } from './calendar-resource.model';
import { CalendarEventComponent, CalendarResourceComponent } from './components';
import { calendarOptions, config } from './config';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() set events(events: CalendarEvent[]) {
    this.eventsArray = events;
    this.calendarOptions.events = events;
  }
  eventsArray: CalendarEvent[];
  @Input() set resources(resources: CalendarResource[]) {
    if (!!resources) {
      this.resourcesArray = resources;
      this.calendarComponent?.getApi().refetchResources();
    }
  }
  resourcesArray: CalendarResource[];

  @Input() options: any;
  @Output() add: EventEmitter<{}> = new EventEmitter();
  @Output() move: EventEmitter<{}> = new EventEmitter();
  @Output() eventClick: EventEmitter<{}> = new EventEmitter();

  @ViewChild('calendar') calendarComponent: FullCalendarComponent; // the #calendar in the template

  // constructor() { }

  calendarVisible = true;
  calendarOptions: any = {};

  eventElements = [];
  eventElementFactory = this.componentFactoryResolver.resolveComponentFactory(
    CalendarEventComponent
  );
  resourceElements = [];
  resourceElementFactory = this.componentFactoryResolver.resolveComponentFactory(
    CalendarResourceComponent
  );

  api: Calendar;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private translate: TranslateService
  ) {}

  destroyEventElements(): void {
    this.eventElements.forEach((eventElement: ViewRef) => {
      eventElement.destroy();
    });
  }

  destroyResourceElements(): void {
    this.resourceElements.forEach((resourceElement: ViewRef) => {
      resourceElement.destroy();
    });
  }

  ngOnDestroy(): void {
    this.destroyEventElements();
    this.destroyResourceElements();
  }

  ngOnInit(): void {
    // options: CalendarOptions = {
    //   ...calendarOptions,

    // };
    this.calendarOptions = {
      ...this.calendarOptions,
      ...calendarOptions,
      locale: this.translate.currentLang,
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      // plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimelinePlugin, resourceTimeGridPlugin],
      initialView: 'timeGridWeek',
      weekends: true,
      editable: true,
      // dateClick: this.handleDateClick.bind(this),
      // events: this.events,
      resources: (fetchInfo, success, failure) =>
        !!this.resourcesArray && success(this.resourcesArray),
      initialDate: '2018-12-05',
      // timeFormat: config.format.time,
      slotLabelFormat: {
        // config.format.time,
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      slotMinTime: `${config.company.dayStartHour - 1}:00`,
      slotMaxTime: `${config.company.dayEndHour + 1}:00`,
      firstDay: config.company.weekStartsOn,
      hiddenDays: config.company.hiddenDays,
      businessHours: {
        start: `${config.company.dayStartHour}:00`,
        end: `${config.company.dayEndHour}:00`,
        dow: [1, 2, 3, 4, 5]
      },

      // eventDidMount: this.eventDidMount.bind(this),
      resourceLabelDidMount: this.resourceLabelDidMount.bind(this),
      dateClick: (info) => this.add?.emit(info),
      eventClick: (info) => this.eventClick?.emit(info),
      eventDrop: (info) => this.move?.emit(info),
      ...this.options
    };
  }

  ngAfterViewInit(): void {
    this.api = this.calendarComponent?.getApi();
  }

  // resourceLabelContent({ resource }: { resource: CalendarResource }) {
  //   return {
  //     html: `
  //       <a [routerLink]="url" [matTooltip]="tooltip">
  //         <img *ngIf="photo" [src]="photo" class="rounded-circle" style="width: 40px; height: 40px">
  //         {{ title }}
  //       </a>
  //     `
  //   };
  // }

  eventDidMount({ event, el }): boolean {
    if (event.rendering === 'background') {
      return true;
    }
    const componentRef = this.eventElementFactory.create(this.injector, null, el.children[0]);
    componentRef.instance.event = event;
    const removeSub: Subscription = componentRef.instance.remove
      .subscribe
      // this.removeEvent.bind(this)
      ();
    componentRef.changeDetectorRef.detectChanges();
    this.appRef.attachView(componentRef.hostView);
    componentRef.onDestroy(() => {
      if (!!removeSub) {
        removeSub.unsubscribe();
      }
      this.appRef.detachView(componentRef.hostView);
    });
    this.eventElements.push(componentRef);
  }

  resourceLabelDidMount({ resource, el }): void {
    const componentRef = this.resourceElementFactory.create(this.injector, null, el);
    componentRef.instance.resource = resource;
    componentRef.changeDetectorRef.detectChanges();
    this.appRef.attachView(componentRef.hostView);
    componentRef.onDestroy(() => {
      this.appRef.detachView(componentRef.hostView);
    });
    this.resourceElements.push(componentRef);
  }
}
