import { Component } from '@angular/core';
import { CompaniesFacade, UsersFacade } from 'app/db/facades';
import { Company } from 'app/db/models';
import { CompanyService } from 'app/db/services';
import { trackBy } from 'app/shared/utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'company-switcher',
  templateUrl: './company-switcher.component.html',
  styleUrls: ['./company-switcher.component.scss']
})
export class CompanySwitcherComponent {
  company$: Observable<Company> = this.companyService.company$;

  companies$: Observable<Company[]> = this.companiesFacade.all$;
  selected$: Observable<Company> = this.companiesFacade.selected$;
  isAdmin$: Observable<boolean> = this.usersFacade.selectedIsAdmin$;

  trackBy = trackBy;

  constructor(
    private companiesFacade: CompaniesFacade,
    private usersFacade: UsersFacade,
    private companyService: CompanyService
  ) {}

  select(company: Company): void {
    this.companiesFacade.select(company);
  }
}
