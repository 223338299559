import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Employee } from '../models';
import { DbState, getDbState } from '../reducers';
import { employeesAdapter, EmployeesState } from '../reducers/employees.reducer';

export const getEmployeesState = createSelector(getDbState, (state: DbState) => state?.employees);

export const {
  selectIds: getEmployeesIds,
  selectEntities: getEmployeesEntities,
  selectAll: getEmployeesAll,
  selectTotal: getEmployeesTotal
} = employeesAdapter.getSelectors(getEmployeesState);

// export const getEmployeesError = createSelector(
//   getEmployeesState,
//   (state: EmployeesState) => state?.error
// );

export const getEmployeesSelectedId = createSelector(
  getEmployeesState,
  (state: EmployeesState) => state?.selectedId
);

export const getEmployeesSelected = createSelector(
  getEmployeesEntities,
  getEmployeesSelectedId,
  (entities: Dictionary<Employee>, selectedId: string) => !!selectedId && entities?.[selectedId]
);

export const getEmployeesForCalendar = createSelector(getEmployeesAll, (employees) =>
  employees?.map((employee: Employee) => ({
    id: employee?.id,
    title: employee?.alias || employee?.name,
    meta: employee,
    eventColor: employee?.color
    // parentId: employee?.place?.id
  }))
);
