import { createAction, props, union } from '@ngrx/store';

import { Work } from '../models';

export const clear = createAction('[DB/Works] clear');

export const select = createAction('[DB/Works] select', props<{ work: Partial<Work> }>());

export const set = createAction('[DB/Works] set', props<{ works: Work[] }>());
export const subscribe = createAction('[DB/Works] subscribe');
export const unsubscribe = createAction('[DB/Works] unsubscribe');

export const add = createAction('[DB/Works] add', props<{ work: Partial<Work> }>());
export const addSuccess = createAction('[DB/Works] add success', props<{ work?: Partial<Work> }>());
export const addFailure = createAction('[DB/Works] add failure');

export const update = createAction('[DB/Works] update', props<{ work: Partial<Work> }>());
export const updateSuccess = createAction(
  '[DB/Works] update success',
  props<{ work?: Partial<Work> }>()
);
export const updateFailure = createAction('[DB/Works] update failure');

export const remove = createAction('[DB/Works] remove', props<{ work: Partial<Work> }>());
export const removeSuccess = createAction(
  '[DB/Works] remove success',
  props<{ work?: Partial<Work> }>()
);
export const removeFailure = createAction('[DB/Works] remove failure');

const all = union({
  select,
  set,
  subscribe,
  unsubscribe,
  add,
  addSuccess,
  addFailure,
  update,
  updateSuccess,
  updateFailure,
  remove,
  removeSuccess,
  removeFailure
});
export type WorksActionsUnion = typeof all;
