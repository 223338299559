// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import 'zone.js/dist/zone-error';

export const environment = {
  production: false,
  hmr: false,
  firebase: {
    // apiKey: 'AIzaSyBjRFxm-VjHIKXjo-r4TvMSwDM__ivuTAA',
    // authDomain: 'planelo-app.firebaseapp.com',
    // databaseURL: 'https://planelo-app.firebaseio.com',
    // projectId: 'planelo-app',
    // storageBucket: 'planelo-app.appspot.com',
    // messagingSenderId: '599476985326'
    projectId: 'planelo-app',
    databaseURL: 'https://planelo-app.firebaseio.com',
    apiKey: 'AIzaSyDckfNWMnVtNh1ZzWUVg5VOrJFWr00J4S0',
    authDomain: 'planelo-app.firebaseapp.com',
    storageBucket: 'planelo-app.appspot.com',
    messagingSenderId: '599476985326',
    appId: '1:599476985326:web:9b3fe215783a86c3fd02ff',
    measurementId: 'G-YEECC4KW0D'
  },
  firebaseChats: {
    projectId: 'planelo-chats',
    databaseURL: 'https://planelo-chats.firebaseio.com'
  },
  firebaseEvents: {
    databaseURL: 'https://planelo-events.firebaseio.com',
    projectId: 'planelo-events'
  },
  firebaseLogs: {
    databaseURL: 'https://planelo-logs.firebaseio.com',
    projectId: 'planelo-logs'
  },
  firebaseBills: {
    databaseURL: 'https://planelo-bills.firebaseio.com',
    projectId: 'planelo-bills',
    storageBucket: 'planelo-bills.appspot.com'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
