export class Time {
  static from = (timeString: string): Time => {
    const hours = +timeString.split(':')[0];
    const minutes = +timeString.split(':')[1];
    return new Time({ hours, minutes });
  };

  hours: number;
  minutes: number;

  constructor({ hours = 0, minutes = 0 } = {}) {
    Object.assign(this, { hours, minutes });
  }

  toString(): string {
    const hours = `${this.hours < 10 ? '0' : ''}${this.hours}`;
    const minutes = `${this.minutes < 10 ? '0' : ''}${this.minutes}`;
    return `${hours}:${minutes}`;
  }
}
