export class QuickPanelFakeDb {
  public static notes = [
    {
      title: 'Best songs to listen while working',
      detail: 'Last edit: May 8th, 2018'
    },
    {
      title: 'Useful subreddits',
      detail: 'Last edit: January 12th, 2018'
    }
  ];

  public static events = [
    {
      title: 'Group Meeting',
      detail: 'In 32 Minutes, Room 1B'
    },
    {
      title: 'Public Beta Release',
      detail: '11:00 PM'
    },
    {
      title: 'Dinner with David',
      detail: '17:30 PM'
    },
    {
      title: 'Q&A Session',
      detail: '20:30 PM'
    }
  ];
}
