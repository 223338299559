import { ChangeDetectionStrategy, Component, HostBinding, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'calendar-event-form',
  templateUrl: 'event-form.component.html',
  styles: [':host { position: absolute; }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarEventCreateComponent {
  @HostBinding('style') style: SafeStyle;
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  start: Date;
  resourceId: string;

  constructor(private sanitizer: DomSanitizer) {}

  open({ date, resource, jsEvent: { x, y } }): void {
    const position = `left: ${x}px; top: ${y}px;`;
    this.style = this.sanitizer.bypassSecurityTrustStyle(position);
    // TODO: save start/resource params in store
    this.start = date;
    this.resourceId = resource.id;
    this.trigger.openMenu();
  }
}
