<!-- <div class="page-layout blank fullwidth">
  <section class="auth-page">
    <header class="auth-page-header">
      <h2 class="auth-page-title main-heading">
        <a routerLink="/">
          <img src="http://www.egobody.pl/gfx/home/logo_egobody.png">
        </a>
      </h2>
    </header>

    <article class="auth-page-content container">
      <router-outlet></router-outlet>
    </article>
  </section>
</div> -->
<content>
  <router-outlet></router-outlet>
</content>
