import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { EventsActions } from '../actions';
import { Event } from '../models';
import {
  getEventsAll,
  getEventsForCalendar,
  getEventsIds,
  getEventsSelected,
  getEventsSelectedId
} from '../selectors/events.selectors';

@Injectable({ providedIn: 'root' })
export class EventsFacade {
  all$: Observable<Event[]> = this.store.pipe(select(getEventsAll));

  forCalendar$: Observable<any[]> = this.store.pipe(select(getEventsForCalendar));

  ids$: Observable<number[] | string[]> = this.store.pipe(select(getEventsIds));

  selected$: Observable<Event> = this.store.pipe(select(getEventsSelected));

  selectedId$: Observable<number | string> = this.store.pipe(select(getEventsSelectedId));

  constructor(private store: Store<{}>) {}

  clear(): void {
    this.store.dispatch(EventsActions.clear());
  }

  select(event: Partial<Event>): void {
    this.store.dispatch(EventsActions.select({ event }));
  }

  subscribe(): void {
    this.store.dispatch(EventsActions.subscribe());
  }

  unsubscribe(): void {
    this.store.dispatch(EventsActions.unsubscribe());
  }

  add(event: Partial<Event>): void {
    this.store.dispatch(EventsActions.add({ event }));
  }

  update(event: Partial<Event>): void {
    this.store.dispatch(EventsActions.update({ event }));
  }

  remove(event: Partial<Event>): void {
    this.store.dispatch(EventsActions.remove({ event }));
  }
}
