import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared.module';
import { CalendarComponent } from './calendar.component';
import { CalendarService } from './calendar.service';
import {
  CalendarEventComponent,
  CalendarEventCreateComponent,
  CalendarResourceComponent
} from './components';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin,
  resourceTimeGridPlugin,
  resourceTimelinePlugin,
  timeGridPlugin
]);

const modules = [
  CommonModule,
  RouterModule,
  FullCalendarModule,
  // FormsModule,
  // ReactiveFormsModule,
  // FlexLayoutModule,
  // FuseDirectivesModule,
  // FusePipesModule,
  // MatFormFieldModule,
  // MatInputModule,
  MatButtonModule,
  MatCardModule,
  MatIconModule,
  MatMenuModule,
  MatTooltipModule,
  NgSelectModule,
  TranslateModule,
  SharedModule
];

@NgModule({
  declarations: [
    CalendarComponent,
    CalendarEventComponent,
    CalendarEventCreateComponent,
    CalendarResourceComponent
  ],
  imports: [...modules],
  exports: [CalendarComponent, ...modules],
  providers: [CalendarService]
})
export class SharedCalendarModule {}
