import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { getUserId } from 'app/main/auth';
import { getPropsArrayWithKeys } from 'app/shared/utils';

import { Company, User, UserAccess } from '../models';
import { DbState, getDbState } from '../reducers';
import { usersAdapter } from '../reducers/users.reducer';
import { getCompaniesEntities } from './companies.selectors';

export const getUsersState = createSelector(getDbState, (state: DbState) => state?.users);

export const {
  selectIds: getUsersIds,
  selectEntities: getUsersEntities,
  selectAll: getUsersAll,
  selectTotal: getUsersTotal
} = usersAdapter.getSelectors(getUsersState);

// export const getUsersError = createSelector(
//   getUsersState,
//   (state: UsersState) => state?.error
// );

// export const getUsersSelectedId = createSelector(
//   getUsersState,
//   (state: UsersState) => state?.selectedId
// );

export const getUsersSelected = createSelector(
  getUsersEntities,
  getUserId,
  (entities: Dictionary<User>, userId: string) => !!userId && entities?.[userId]
);

export const getUsersSelectedIsAdmin = createSelector(
  getUsersSelected,
  (user: User) => user?.isAdmin
);

export const getUsersSelectedAccess = createSelector(getUsersSelected, (user: User) =>
  getPropsArrayWithKeys(user?.access)
);

export const getUsersSelectedAccessInfo = createSelector(
  getUsersSelectedAccess,
  getCompaniesEntities,
  (accessArray: UserAccess[], companies: Dictionary<Company>) =>
    accessArray?.map(({ _key, ...access }: UserAccess) => ({
      ...access,
      company: companies?.[_key]
    }))
);
