import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { Employee } from 'app/db/models';
import { EmployeesService, UserService } from 'app/db/services';
import { ChatPanelService } from 'app/layout/components/chat-panel/chat-panel.service';
import { trackBy } from 'app/shared/utils';
import { UserInfo } from 'firebase';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  employees$: Observable<Employee[]> = this.employeesService.employees$;
  messages$: Observable<any[]>;
  chat: any;
  selectedContact: any;
  sidebarFolded: boolean;
  user: any;
  user$: Observable<UserInfo> = this.userService.user$;

  trackBy = trackBy;

  @ViewChild('replyForm')
  private _replyForm: NgForm;

  @ViewChild('replyInput')
  private _replyInput: ElementRef;

  @ViewChildren(FusePerfectScrollbarDirective)
  private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

  private _chatViewScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _chatPanelService: ChatPanelService,
    private _fuseSidebarService: FuseSidebarService,
    private employeesService: EmployeesService,
    private userService: UserService
  ) {
    this.selectedContact = null;
    this.sidebarFolded = true;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._fuseSidebarService
      .getSidebar('chatPanel')
      .foldedChanged.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((folded) => {
        this.sidebarFolded = folded;
      });
  }

  ngAfterViewInit(): void {
    this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
      return directive.elementRef.nativeElement.id === 'messages';
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  foldSidebarTemporarily(): void {
    this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily();
  }

  unfoldSidebarTemporarily(): void {
    this._fuseSidebarService.getSidebar('chatPanel').unfoldTemporarily();
  }

  toggleSidebarOpen(): void {
    this._fuseSidebarService.getSidebar('chatPanel').toggleOpen();
  }

  toggleChat(contact): void {
    if (this.selectedContact && contact.employeeId === this.selectedContact.employeeId) {
      this.resetChat();
    } else {
      this.unfoldSidebarTemporarily();
      this.selectedContact = contact;
      this.messages$ = this._chatPanelService.getMessages(this.selectedContact.employeeId).pipe(
        tap((messages) => this._prepareChatForReplies()),
        map((messages: any) =>
          messages?.map((message, index) => ({
            ...message,
            isFirst:
              index === 0 ||
              (messages[index - 1] && messages[index - 1].author !== message?.author),
            isLast:
              index === messages?.length - 1 ||
              (messages[index + 1] && messages[index + 1].author !== message?.author)
          }))
        )
      );
    }
  }

  private _prepareChatForReplies(): void {
    setTimeout(() => {
      if (this._chatViewScrollbar) {
        this._chatViewScrollbar.update();

        setTimeout(() => {
          this._chatViewScrollbar.scrollToBottom(0);
        });
      }
    });
  }

  resetChat(): void {
    this.selectedContact = null;
    this.messages$ = null;
  }

  reply(event): void {
    event.preventDefault();

    if (!this._replyForm.form.value.message) {
      return;
    }
    this._chatPanelService
      .sendMessage(this._replyForm.form.value.message, this.selectedContact.employeeId)
      .pipe(tap((docRef) => this._prepareChatForReplies()))
      .toPromise();

    this._replyForm.reset();
  }
}
