import { createAction, props, union } from '@ngrx/store';

import { Credential } from './credential.model';
import { User } from './user';

export const login = createAction('[Auth] login', props<{ credential: Credential }>());
export const loginWithFacebook = createAction('[Auth] login with facebook');
export const loginWithGoogle = createAction('[Auth] login with google');
export const loginSuccess = createAction('[Auth] login success', props<{ user: Partial<User> }>());
export const loginFailure = createAction('[Auth] login failure', props<{ error: any }>());
export const loginRedirect = createAction('[Auth] login redirect');
export const logout = createAction('[Auth] logout');
export const logoutSuccess = createAction('[Auth] logout success');

// export class SignUp implements Action {
//   readonly type = AuthActionTypes.SignUp;
//   constructor(public payload: User) { }
// }
// export class SignUpSuccess implements Action {
//   readonly type = AuthActionTypes.SignUpSuccess;
//   constructor(public payload: User) { }
// }
// export class SignUpFailure implements Action {
//   readonly type = AuthActionTypes.SignUpFailure;
// }
// export class DeleteAccount implements Action {
//   readonly type = AuthActionTypes.DeleteAccount;
//   constructor(public payload: User) { }
// }
// export class DeleteAccountSuccess implements Action {
//   readonly type = AuthActionTypes.DeleteAccountSuccess;
// }
// export class DeleteAccountFailure implements Action {
//   readonly type = AuthActionTypes.DeleteAccountFailure;
// }

// export class UpdateAccount implements Action {
//   readonly type = AuthActionTypes.UpdateAccount;
//   constructor(public payload: { uid: string; user: User }) { }
// }
// export class UpdateAccountSuccess implements Action {
//   readonly type = AuthActionTypes.UpdateAccountSuccess;
//   constructor(public payload: User) { }
// }
// export class UpdateAccountFailure implements Action {
//   readonly type = AuthActionTypes.UpdateAccountFailure;
// }
// export class Query implements Action {
//   readonly type = AuthActionTypes.Query;
// }

export const updateProfile = createAction(
  '[Auth] update profile',
  props<{ user: Partial<User> }>()
);

const all = union({
  login,
  loginWithFacebook,
  loginWithGoogle,
  loginSuccess,
  loginFailure,
  loginRedirect,
  logout,
  logoutSuccess,
  updateProfile
});
export type AuthActionsUnion = typeof all;
