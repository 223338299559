import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

import { CompaniesFacade } from '../facades';
import { ActivitiesService } from '../services';

@Injectable()
export class ActivitiesEffects {
  // subscribe$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ActivitiesActions.subscribe),
  //     switchMap(() => this.companiesFacade.selectedId$),
  //     switchMap((companyId: string) =>
  //       this.activitiesService.get({ companyId }).pipe(
  //         takeUntil(this.actions$.pipe(ofType(ActivitiesActions.unsubscribe))),
  //         map((activities: Activity[]) => ActivitiesActions.set({ activities }))
  //       )
  //     )
  //   )
  // );

  constructor(
    protected actions$: Actions,
    protected activitiesService: ActivitiesService,
    private companiesFacade: CompaniesFacade
  ) {
    // super(actions$, activitiesService);
  }
}
