import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPipesModule } from 'ngx-pipes';

import { AvatarComponent, ListComponent } from './components';
import { FormConnectDirective } from './directives/form-connect.directive';
import { EditableModule } from './editable/editable.module';
import { DistanceInWordsTranslatePipe, LocaleDatePipe } from './pipes';
import { LoaderService } from './services/loader.service';

const modules = [
  CommonModule,
  EditableModule,
  ScrollingModule,
  NgPipesModule
  // FormsModule,
  // ReactiveFormsModule,
  // FlexLayoutModule,
  // FuseDirectivesModule,
  // FusePipesModule,
  // MatFormFieldModule,
  // MatInputModule,
  // MatCheckboxModule,
  // MatButtonModule,
  // MatIconModule,
];

const components = [AvatarComponent, ListComponent, LocaleDatePipe, DistanceInWordsTranslatePipe];

const directives = [FormConnectDirective];

@NgModule({
  declarations: [...components, ...directives],
  imports: [...modules],
  exports: [...components, ...directives, ...modules],
  providers: [LoaderService]
})
export class SharedModule {}
