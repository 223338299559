import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserEffects } from './user.effects';
import { reducer } from './user.reducer';
import { UserService } from './user.service';

@NgModule({
  imports: [EffectsModule.forFeature([UserEffects]), StoreModule.forFeature('users', reducer)],
  providers: [UserEffects, UserService]
})
export class UserModule {}
