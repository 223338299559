import { Timestamp } from '@firebase/firestore-types';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { Event } from '../models';
import { DbState, getDbState } from '../reducers';
import { eventsAdapter, EventsState } from '../reducers/events.reducer';
import { getClientsEntities } from './clients.selectors';

export const getEventsState = createSelector(getDbState, (state: DbState) => state?.events);

export const {
  selectIds: getEventsIds,
  selectEntities: getEventsEntities,
  selectAll: getEventsAll,
  selectTotal: getEventsTotal
} = eventsAdapter.getSelectors(getEventsState);

// export const getEventsError = createSelector(
//   getEventsState,
//   (state: EventsState) => state?.error
// );

export const getEventsSelectedId = createSelector(
  getEventsState,
  (state: EventsState) => state?.selectedId
);

export const getEventsSelected = createSelector(
  getEventsEntities,
  getEventsSelectedId,
  (entities: Dictionary<Event>, selectedId: number) => !!selectedId && entities?.[selectedId]
);

export const getEventsForCalendar = createSelector(
  getEventsAll,
  getClientsEntities,
  (events, clients) =>
    events.map((event: Event) => {
      const client = !!event.client && clients[event.client.id];
      const title = !!client ? `${client.name} ${client.lastname}` : '';
      return {
        id: event.id,
        title,
        // description: '',
        meta: event,
        start: !!event.start && new Date((event.start as Timestamp).seconds * 1000),
        // !!event.start && (event.start as Timestamp).toDate(),
        end: !!event.end && new Date((event.end as Timestamp).seconds * 1000),
        // !!event.end && (event.end as Timestamp).toDate(),
        resourceId: !!event.employee && event.employee.id,
        parentId: !!event.place && event.place.id,
        constraint: 'businessHours'
      };
    })
);
