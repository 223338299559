import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({ selector: '[ptmIfFormError]' })
export class FormErrorDirective {
  private hasView = false;

  constructor(
    private formGroupDirective: FormGroupDirective,
    private templateRef: TemplateRef<{}>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input()
  set ptmIfFormError([controlName, errorType, suppressIfAny = []]: [string, string, string[]]) {
    if (!this.formGroupDirective.form) {
      return;
    }

    const control = this.formGroupDirective.form.get(controlName);
    const condition =
      control.hasError(errorType) &&
      suppressIfAny.every((supressingType: string) => !control.hasError(supressingType));

    if (!condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
