import { NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';

import { environment } from '../environments/environment';

export function AngularFireAuthProject(platformId: {}, zone: NgZone): AngularFireAuth {
  return new AngularFireAuth(environment.firebase, 'firebase-project-auth', platformId, zone);
}

export function AngularFirestoreProject(platformId: {}, zone: NgZone): AngularFirestore {
  return new AngularFirestore(
    environment.firebase,
    'firebase-project',
    false,
    null,
    platformId,
    zone,
    null
  );
}

export function AngularFirestoreFunctions(zone: NgZone): AngularFireFunctions {
  return new AngularFireFunctions(
    environment.firebase,
    'firebaseFunctions',
    zone,
    'us-central1',
    ''
  );
}

export function AngularFirestoreChats(platformId: {}, zone: NgZone): AngularFirestore {
  return new AngularFirestore(
    environment.firebaseChats,
    'firebase-chats',
    false,
    null,
    platformId,
    zone,
    null
  );
}

export function AngularFirestoreEvents(platformId: {}, zone: NgZone): AngularFirestore {
  return new AngularFirestore(
    environment.firebaseEvents,
    'firebase-events',
    false,
    null,
    platformId,
    zone,
    null
  );
}

export function AngularFirestoreLogs(platformId: {}, zone: NgZone): AngularFirestore {
  return new AngularFirestore(
    environment.firebaseLogs,
    'firebase-logs',
    false,
    null,
    platformId,
    zone,
    null
  );
}

export function AngularFirestoreBills(platformId: {}, zone: NgZone): AngularFirestore {
  return new AngularFirestore(
    environment.firebaseBills,
    'firebase-bills',
    false,
    null,
    platformId,
    zone,
    null
  );
}

export function AngularFireStorageBills(platformId: {}, zone: NgZone): AngularFireStorage {
  return new AngularFireStorage(
    environment.firebaseBills,
    'firestorage-bills',
    environment.firebaseBills.storageBucket,
    platformId,
    zone
  );
}

export function AngularFireFunctionsBills(zone: NgZone): AngularFireFunctions {
  return new AngularFireFunctions(
    environment.firebaseBills,
    'firefunctions-bills',
    zone,
    'us-central1',
    ''
  );
}
